body,Html{
  box-sizing: border-box;
  font-family: 'Rubik';
}

.figma-container-landing {
  width: 100%;
  min-height: 80vh;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: white;
  position: relative;
}

.frame1707478343-landing {
  width: 90%;
  max-width: 661px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

}

.frame21-landing {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 5%;
  height: 150px; 

}

.easylaw-logo-011-landing {
  width: 122px;
  height: auto;
}

.frame1707478210-landing {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.frame14-landing {
  padding: 24px;
  background: #F1FBFE;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.title-landing {
  width: 100%;
  text-align: center;
  color: #202341;
  font-size: 2rem;
  font-family: 'Rubik';
  font-weight: 600;
  line-height: 1.5;
}

.frame16-landing {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
}

.exam-date-landing {
  text-align: center;
  color: #0C82AB;
  font-size: 1.5rem;
  font-family: 'Rubik';
  font-weight: 600;
  line-height: 1.5;
}

.frame22-landing {
  padding: 2%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 80%;
}

.text-easy {
  color: black;
  font-size: 16px;
  margin-top: 2%;
  font-family: 'Rubik';
  font-weight: 600;
  line-height: 1.5;
  direction: rtl;
}

.easylaw41-landing {
  width: 100%;
  margin-top: 10%;
  text-align: center;
}

.easylaw41-landing span {
  display: block;
  font-size: 16px;
  color: black; 
}

.easylaw41-landing span:first-child {
  font-size: 1.5rem;
  color: #202341;
}

.easylaw41-landing span:nth-child(2) {
  color: black;
  font-size: 0.9rem;
  font-family: 'Rubik';
  font-weight: 450;
  line-height: 1.5;
}

.frame1707478411-landing {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.button-landing-1 {
  height: 9vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90% !important;
  font-weight: 600;
  margin-top: 6vh;
  background: #21FFF5;
  border-radius: 8px;
  border: none;
  color: black; 
  font-size: 1.25rem;
  cursor: pointer;
}

.button-landing-1 .arrow-left {
  margin-right: 10px; 
  width: 20px; 
  height: 20px; 
}

.button-landing-2 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 16px;
  border-radius: 8px;
  font-family: 'Rubik';
  font-weight: 600;
  text-align: center;
  color: #202341;
  padding-bottom: 50px;
}

.button-login-landing {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.button-login-landing div {
  text-align: center;
}

.button-login-landing span:first-child {
  color: #202341;
  font-size: 1.25rem;
  font-family: 'Rubik';
  font-weight: 600;
}

.button-login-landing .login-link {
  color: #316EE5;
  font-size: 1.25rem;
  font-family: 'Rubik';
  font-weight: 600;
  padding-right: 10px;
  bottom: 5px;
}


















/* מיכל מרכזי */
.inactive-message-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #001f3f; /* רקע כחול כהה */
}

/* סגנון הכיתוב */
.inactive-message {
  text-align: center;
  font-size: 2rem; /* גופן גדול במיוחד */
  font-family: 'Rubik' ;/* גופן כתב יד */
  font-weight: 200;
  color: white; /* צבע לבן */
  line-height: 1.2; /* גובה שורה */
  direction: rtl;
}

/* סגנון הפסקאות */
.inactive-message p {
  margin: 20px 0; /* רווח בין הפסקאות */
}

.animated-link {
  color: white;
  text-decoration: none;
  position: relative;
  font-size: 2rem; /* גודל הקישור */
  cursor: pointer;
}


.animated-link::after, .animated-link::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 3px;
  background-color: #00ffff; /* צבע תכלת זורח */
  left: 0;
  transform: scaleX(0);
  transition: transform 1s ease-in-out;
  transform-origin: left;
  clip-path: polygon(0 0, 70% 15%, 20% 100%, 10% 65%); /* קווים לא ישרים */
  /* clip-path: polygon(0 0, 100% 15%, 90% 100%, 10% 85%); */
}

.animated-link::before {
  bottom: -10%; /* הקו הראשון */
}

.animated-link::after {
  bottom: -15%; /* הקו השני מתחת */
  clip-path: polygon(0 20%, 95% 50, 10% 90%, 25% 85%); /* קווים מעוקלים מעט */
  /* clip-path: polygon(0 10%, 95% 0, 100% 90%, 5% 100%); */
}

.animated-link.animate::before {
  transform: scaleX(1); /* מתיחת הקו הראשון */
}

.animated-link.animate::after {
  transform: scaleX(1); /* מתיחת הקו השני */
  transition-delay: 0.5s; /* השהייה של 0.5 שניות אחרי הקו הראשון */
}
.title-landing {
  color: #202341;
}

@media (max-width: 768px) {
  .inactive-message {
    text-align: center;
    font-size: 1.4rem;
    font-family: 'Rubik';
    color: white; /* צבע לבן */
    line-height: 1.2; /* גובה שורה */
    direction: rtl;
  }
  .animated-link {
    color: white;
    text-decoration: none;
    position: relative;
    font-size: 2rem; /* גודל הקישור */
    cursor: pointer;
  }
  
  .figma-container-landing {
    padding: 16px;
  }
  .frame1707478343-landing {
    width: 100%;
    gap: 12px;
  }
  .title-landing {
    font-size: 1.5rem;
  }
  .exam-date-landing {
    font-size: 20px;
  }
 
  .button-landing-1 {
    font-size: 16px;
    width: 70%; /* 100% - 12% מכל צד */

  }
  .button-landing-2 {
    font-size: 1rem;
  }
  .frame21-landing {
    height: 150px; /* גובה מוגדל ללוגו */
    padding-bottom: 5%;

  }
  .easylaw41-landing span {
    font-size: 14px; /* גודל גופן קבוע גם בטאבלטים */
  }

  .easylaw41-landing span:first-child {
    font-size: 1.5rem;
  }
}

@media (max-width: 480px) {
  .inactive-message {
    text-align: center;
    font-size: 1.4rem;
    font-family: 'Rubik';
    color: white;
    line-height: 1.2;
    direction: rtl;
}
  .animated-link {
    color: white;
    text-decoration: none;
    position: relative;
    font-size: 2rem; /* גודל הקישור */
    cursor: pointer;
  }
  
  .easylaw-logo-011-landing {
    width: 100px;
    height: auto;
}
  .figma-container-landing {
    padding: 12px;
   
  }
  .frame1707478210-landing {
    padding-left: 2vw;
    padding-right: 2vw;
}
  .frame1707478343-landing {
    width: 100%;
    gap: 8px;
  }
  .frame14-landing {
    padding: 24px;
    background: #F1FBFE;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    left: 5%;
    right: 5%;
  }
  .frame21-landing {
    padding-bottom: 5%;
    height: 120px; /* גובה מוגדל ללוגו */
  }
  .title-landing {
    font-size: 1.45rem;
  }
  .exam-date-landing {
    font-size: 20px;
    white-space: nowrap;
    
  }
  /* .button-landing-1 {
    left: 15%;
    right: 15%;
    
  } */
  .button-landing-1 {
    width: 70%;
    font-family: 'Rubik';
    white-space: nowrap;
    font-size: 1.15rem;
    margin-top: 6vh;

}
  .button-landing-2 {
    font-size: 0.875rem;
  }
  .easylaw41-landing span:first-child {
    font-size: 1.14rem;
  }
  
  .text-easy {
    font-size: 1rem;
    direction: rtl;
    font-size: 3px;
  }
  .button-login-landing .login-link {
    color: #316EE5;
    font-size: 1rem;
    font-family: 'Rubik';
    font-weight: 600;
    padding-right: 10px;
    bottom: 5px;
  }
  .button-login-landing span:first-child {
    color: #202341;
    font-size: 1rem;
    font-family: 'Rubik';
    font-weight: 600;
  }
  
}
@media (max-width: 768px) {
  .inactive-message {
    text-align: center;
    font-size: 2rem;
    font-family: 'Rubik';
    color: white; /* צבע לבן */
    line-height: 1.2; /* גובה שורה */
    direction: rtl;
  }
  .animated-link {
    color: white;
    text-decoration: none;
    position: relative;
    font-size: 2rem;
    cursor: pointer;
}
}