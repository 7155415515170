/* SignIn.css */
html, body {
  height: 100%;
  position: fixed;
  width: 100%;
}

.container-sign {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.logo-sign {
  margin-bottom: 20px;
}

.form-container-sign {
  width: 100%;
  background: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.form-title-sign {
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 20px;
}

.text-field-sign input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.submit-button-sign {
  width: 100%;
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.submit-button-sign:hover {
  background-color: #45a049;
}

.error-message {
  color: red;
  font-size: 0.9rem;
  margin-top: 5px;
}

.login-link-container {
  text-align: center;
  margin-top: 20px;
}

.login-link {
  color: blue;
  cursor: pointer;
}

.showPopupVerify-login {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
}
























































/* Disable scrolling */
html, body {
  overflow: hidden;
  height: 100%;
  top: 0;
  margin: 0;
  padding: 0;
}

/* 

* {
  box-sizing: border-box;
}




 */






/* סגנון בסיסי עבור כל המכשירים */
.sign-in-form {
  /* max-width: 500px; */
  margin: 0 auto; 
  /* padding: 20px; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.submit-button-sign {
  margin-top: auto;
  margin-bottom: 0%;
  height: 6vh;
  font-size: 14px;
  padding-left: 15vw;
  padding-right: 15vw;
}

/* מדיה עבור מסכים קטנים מ-600 פיקסלים */
@media (max-width: 600px) {
  .sign-in-form {
    width: 100%; /* משתמש ברוחב מלא של המסך */
    box-shadow: none; 
  }


  .text-input-sign {
    font-size: 16px; /* גודל טקסט מתאים למסכים קטנים */

    width: 100%; /* מבטיח שהשדה יתפוס את כל הרוחב */
  }

  .switch-sign {
    display: flex;
    justify-content: space-between; /* מרווח בין האלמנטים */
    align-items: center; 
  }

  .submit-button-sign {
    width: 100%; 
    font-size: 18px; 
  }
}


@media (max-width: 400px) {

  .text-input-sign {
    font-size: 14px; /* גודל טקסט קטן יותר */
  }

  .submit-button-sign {
    font-size: 16px; /* גודל טקסט מתאים */
  }
}

.form-field-sign {
  display: flex;
  flex-direction: row; /* שינוי מ-column ל-row */
  flex-wrap: wrap; /* מאפשר לתוכן לעבור לשורה הבאה רק אם אין מספיק מקום */
  gap: 8px; /* מרווח בין האובייקטים */
}

.terms-label-sign {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-family: 'Rubik';
  color: black;
  white-space: nowrap; /* מונע מעבר שורה */
}

/* General Styles */
.container-sign {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  position: relative;
  /* padding: 24px; */
  box-sizing: border-box; 
  direction: rtl; 
  text-align: right; 
  /* padding-bottom: 12px; */
}
/* SignIn.css */
.switch-label-sign {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.switch-input-sign {
  appearance: none;
  width: 40px;
  height: 20px;
  background: white;
  border-radius: 20px;
  position: relative;
  outline: none;
  cursor: pointer;
  transition:  0.3s ease;
  margin-right: 10px; /* רווח בין ה-checkbox לטקסט */
}

.switch-input-sign.active {
  background: #17C2D0; /* צבע תכלת כשפעיל */
}

.switch-slider-sign {
  content: '';
  position: absolute;
  width: 18px;
  height: 18px;
  top: 1px;
  left: 1px;
  background: #fff;
  border-radius: 50%;

}



.switch-slider-sign.active {
  background: #17C2D0; /* צבע תכלת גם לסליידר */
}

.logo-sign {
  position: absolute;
  left: 130px;
  top: 90px;
}

.logo-img-sign {
  width: 122px;
  height: 125px;
}

.form-container-sign {
  /* position: absolute;
  margin-top: 50%; */
  width: 70%;
  max-width: 35vw;
  padding: 2%;
  background: white;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 2px solid #E6F1FF;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-title-sign {
  font-size: 32px;
  font-family: 'Rubik';
  font-weight: 600;
  color: #202341;
  text-align: center;
  margin-bottom: 5%;
}

.form-content-sign {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/* .form-field-sign {
  display: flex;
  flex-direction: column;
} */

.form-label-sign {
  font-size: 14px;
  font-family: 'Rubik';
  color: #202341;
  font-weight: '400';
  line-height: 20px;
  word-wrap: break-word;
}

.text-field-sign {
  width: 100%;
}

.text-input-sign {
  width: 100%;
  height: 48px;
  /* padding: 0 16px; */
  border: 1px solid #878787;
  border-radius: 8px;
  font-size: 16px;
  font-family: 'Rubik';
  color: #878787;
  box-sizing: border-box;
  direction: rtl;
}

.switch-label-sign {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 16px;
  font-family: Rubik;
  color: black;
}

.switch-input-sign {
  display: none;
}

.SwitchOn-t-b {
  width: 41px;
  height: 21px;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.SwitchOn-t-b.active .Rectangle700-t-b {
  background: #17C2D0;
}

.switch-slider-sign {
  width: 41px;
  height: 21px;
  background: #8596AF;
  border-radius: 21px;
  position: relative;
  cursor: pointer;
}

.switch-slider-sign::before {
  content: "";
  position: absolute;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  background: white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  left: 1px;
  top: 1px;
  transition: 0.3s;
}

.switch-input-sign:checked + .switch-slider-sign::before {
  transform: translateX(20px);
}

.terms-label-sign {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-family: Rubik;
  color: black;
}

.terms-link-sign {
  color: #316EE5;
  font-weight: 500;
  margin-right: 3px;
}

.terms-input {
  display: contents;
}

.submit-button-sign {
  width: 100%;
  height: 8vh;
  background: #21FFF5;
  border: none;
  border-radius: 8px;
  font-size: 24px;
  font-family: Rubik;
  font-weight: 600;
  color: black;
  cursor: pointer;
  transition: 0.3s;
}

.submit-button-sign:hover {
  background: #1AC8E6;
}

.login-link-container {
  position: fixed; /* מיקום קבוע ביחס לחלון הדפדפן */
  bottom: 3vh; /* מרווח מהתחתית של המסך */
  left: 50%; /* מיקום באמצע רוחב המסך */
  transform: translateX(-50%); /* ממרכז את האלמנט באמצע */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  z-index: 1000; /* שומר על האלמנט מעל תוכן אחר */
}

.login-link {
  color: #316EE5;
  font-weight: 500;
  cursor: pointer;
}

.showPopupVerify-login {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.spn-sign{
  direction: rtl;
}
/* Responsive Styles */
@media (max-width: 1024px) {

  .logo-sign {
    left: 10px;
    top: 10px;
  }
  .form-title-sign {
    font-size: 28px;
  }

  .text-input-sign {
    height: 40px;
    font-size: 14px;
  }

  .submit-button-sign {
  
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .form-content-sign {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .container-sign {
    padding: 40px;
  }
  .logo-sign {
    left: 50%;
    right: 50%;
    display: flex;
    justify-content: center;
  }
  .form-container-sign {
    padding: 10%;
    border: none;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  .login-link-container {
    white-space: nowrap;
    position: fixed;
    bottom: 0vh;
  
}
  .form-title-sign {
    font-size: 24px;
  }

  .text-input-sign {
    height: 36px;
    font-size: 14px;
  }

  .submit-button-sign {
    height: 45px;
    font-size: 18px;
  } 
  .container-sign {
    height: 90vh;
  }

  .form-field-sign {
    padding-left: 15px;
    padding-right: 15px;
  }
  .logo-img-sign{
    width:90px ;
    height:95px ;
  }
  .logo-sign {
    left: 50%;
    right: 50%;
    display: flex;
    justify-content: center;
  }
  .form-container-sign {
    padding: 3%;

    height: 65vh;
    position: absolute;
    width: 70%;
    max-width: 65vw;

    background: white;
    border-radius: 16px;
    border: 2px solid #E6F1FF;
    display: flex;
    flex-direction: column;
    align-items: center;
}
  .form-title-sign {
    font-size: 20px;
  }

  .text-input-sign {
    height: 32px;
    font-size: 12px;
  }

  .submit-button-sign {
    height: 40px;
    font-size: 16px;
    padding-left: 15vw;
    padding-right: 15vw;
  }
}

@media (max-width: 480px) {
  .form-content-sign {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .container-sign {
    margin-top: 2vh;
    height: 90vh;
  }

  .form-field-sign {
    padding-left: 15px;
    padding-right: 15px;
  }
  .terms-label-sign{
    font-size: 100% !important;

  }
  .logo-img-sign{
    width:90px ;
    height:95px ;
  }
  .logo-sign {
    left: 50%;
    right: 50%;
    display: flex;
    justify-content: center;
  }
  /* .form-container-sign {
    
    padding: 12px;
    margin-bottom: 3px; 
    height: 65vh;
    position: absolute;
    margin-top: 60%;
    margin-bottom: 80px; 
    width: 70%;
    max-width: 70vw;
    padding: 18px;
    background: white;
    border-radius: 16px;
    border: 2px solid #E6F1FF;
    display: flex;
    flex-direction: column;
    align-items: center;
  } */
  .form-container-sign {
    height: auto !important;
    position: absolute;
    margin-top: 10vh;
    /* margin-bottom: 15vh; */
    width: 70%;
    padding: 5%;
    background: white;
    border-radius: 16px;
    border: 2px solid #E6F1FF;
    display: flex;
    flex-direction: column;
    align-items: center;
}
  .form-title-sign {
    font-size: 20px;
  }
  .login-link-container {
    margin-bottom: 8vh;
    white-space: nowrap;
    position: fixed;
    bottom: 0vh;
}
  .text-input-sign {
    height: 32px;
    font-size: 12px;
  }

  .submit-button-sign {
    height: 40px;
    font-size: 16px;
    padding-left: 20vw;
    padding-right: 20vw;
  }
}

/* Specific styles for iPhone */
@media (max-width: 375px) {
  .form-content-sign {
    width: 70vw;
    display: flex;
    flex-direction: column;
    gap: 15px;
}
  .container-sign {
    padding: 8px;
  }

  .form-field-sign {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

  }

  .terms-input-sign {
    width: auto; /* מוודא שהצ׳קבוקס לא יגדיל את השורה */
  }
  .login-link-container {
    position: fixed;
    bottom: 0vh;
    white-space:nowrap;
}

  .terms-link-sign {
    white-space: nowrap; /* מונע מעבר שורה בטקסט */
  }
  .form-container-sign {
  
    height: 65vh;
    position: absolute;
    margin-top: 25vh;
    margin-bottom:15vh;
    width: 70%;
    padding: 5%;
    background: white;
    border-radius: 16px;
    border: 2px solid #E6F1FF;
    display: flex;
    flex-direction: column;
    align-items: center;
}

  .form-title-sign {
    font-size: 18px;
  }

  .text-input-sign {
    height: 28px;
    font-size: 12px;
  }

  .submit-button-sign {
    height: 6vh;
    font-size: 14px;
    padding-left: 15vw;
    padding-right: 15vw;
}





    .terms-label-sign {
        font-size: 12px !important;
        display: inline-flex;
        align-items: center;
        white-space: nowrap;
        gap: 5px;
    }
  .terms-input-sign {
    display: inline;
    margin-right: 5px; /* רווח מצד ימין */
  }
  
  .terms-link-sign {
    display: inline;
    cursor: pointer;
  }
  
}

.error .text-input-sign {
  border: 1px solid red;
}

.error-message {
  color: red;
  font-size: 12px;
}
