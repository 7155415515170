
body,html{
  box-sizing: border-box;
  height: 100%;
  overflow: hidden;
  position: fixed;
  width: 100%;
}


.login-container-login {
  width: 100%;
  height: 100vh; 
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  position: relative;
  overflow: hidden; 
}

.frame21-login {
  width: 80%;

  top: 10%;
  position: absolute;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
}

.easylaw-logo-login {
  width: 122.24px;
  height: 125.44px;
}

.frame1707478209-login {
  width: 100%;
  max-width: 40vw;
  padding: 24px;
  border-radius: 16px;
  border: 2px solid #E6F1FF;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;

}

.frame1707478208-login {
  flex: 1;
  align-self: stretch;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
}

.title-login {
  width: 395px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.title-text-login {
  flex: 1;
  text-align: center;
  color: #202341;
  font-size: 32px;
  font-family: 'Rubik';
  font-weight: 600;
  line-height: 40px;
  word-wrap: break-word;
}

.frame19-login {
  align-self: stretch;
  height: 30vh;
  width: 30vw;
  padding-left: 24px;
  padding-right: 24px;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 48px;
}

.text-field-caption-login {
  align-self: stretch;
  height: 76px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 4px;
}

.frame21684-login {
  align-self: stretch;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
}

.label-login {
  text-align: right;
  color: #202341;
  font-size: 16px;
  font-family: 'Rubik';
  font-weight: 500;
  line-height: 24px;
  word-wrap: break-word;
}

.text-field-phone-login {
  width: 100%;
}

.clone-login {
  width: 100%;
  height: 48px;
  padding: 0 16px;
  border: 1px solid #878787;
  border-radius: 8px;
  font-size: 16px;
  font-family: Rubik;
  color: #878787;
  box-sizing: border-box;
  direction: rtl;
}

.button-container-login {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.submit-button-login {
  width: 100%;
  height: 60px;
  background: #21FFF5;
  border: none;
  border-radius: 8px;
  font-size: 24px;
  font-family: Rubik;
  font-weight: 600;
  color: black;
  cursor: pointer;
  transition: 0.3s;
}

.submit-button-login:hover {
  background: #1AC8E6;
}

.showPopupVerify {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.Sms-v-s-p {
  width: 30vw;
  height: 40vh;
  padding: 24px;
  background: white;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.Title-v-s-p {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.TitleText-v-s-p {
  text-align: center;
  color: #202341;
  font-size: 32px;
  font-family: 'Rubik';
  font-weight: 600;
  line-height: 40px;
}

.SmsMessage-v-s-p {
  text-align: center;
  color: black;
  font-size: 16px;
  font-family: 'Rubik';
  font-weight: 400;
  line-height: 10px;
  word-wrap: break-word;
}

.VerificationCode-v-s-p {
  width: 25vw;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex-direction: row-reverse;
}

.VerificationInput-v-s-p {
  border-radius: 16px;
  border: 1px solid #878787;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10%;
  min-height: 80%;
}

.VerificationInput-v-s-p {
  text-align: center;
  color: black;
  font-size: 56px;
  font-family: 'Rubik';
  font-weight: 400;
  line-height: 40px;
  word-wrap: break-word;
}

.ButtonText-v-s-p {
  border: none;
  justify-content: flex-start;
  align-items: flex-start;
  height: 18%;
  align-self: stretch;
  background: #21FFF5;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: black;
  font-size: 18px;
  font-family: 'Rubik';
  font-weight: 600;
  line-height: 24px;
  word-wrap: break-word;
}

.switch-to-email{
  text-decoration: none;
    color: #202341; 
    font-weight: bold;
    cursor: pointer;
    transition: color 0.3s ease;
}
.switch-to-sms{
  text-decoration: none;
    color: #202341; 
    font-weight: bold;
    cursor: pointer;
    transition: color 0.3s ease;
}
.Input-Email-vsp {
  width: 60%;
  padding: 10px;
  border: 2px solid #030407;
  border-radius: 10px;
  font-size: 1rem;
  margin-bottom: 15px;
  transition: border 0.3s ease-in-out;
}

.Input-Email-vsp:focus {
  outline: none;
  border-color: #030303;
}

.send-email-btn{
  width: 70%;
    padding: 12px;
    background-color: #21FFF5;
    color: black;
    font-size: 1rem;
    font-weight: 600;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.send-email-btn:hover {
 
  background-color: #21FFF5;
}

.send-email-btn:focus {
  outline: none;
  box-shadow: 0 0 10px rgba(69, 160, 73, 0.3);
}
.switch-to-email-link a{
  color: rgb(8, 31, 148);
  text-decoration: none;
  direction: rtl;
}

@media (max-width: 768px) {
  .login-container-login {
 
  }

  .frame21-login {
    width: 100%; 
    top: 10%; 
    justify-content: center;
  }

  .easylaw-logo-login {
    width: 100px;
    height: 100px;
  }

  .frame1707478209-login {
    width: 90%; 

  }

  .title-login {
    width: 100%; 
  }

  .title-text-login {
    font-size: 28px;
    line-height: 36px;
  }

  .clone-login {
    height: 40px; 
  }

  .submit-button-login {
    height: 50px;
    font-size: 20px; 
  }

  .VerificationCode-v-s-p {
    width: 90%;
    padding: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    flex-direction: row-reverse;
}
.VerificationInput-v-s-p {
  text-align: center;
  color: black;
  font-size: 36px;
  font-family: 'Rubik';
  font-weight: 400;
  line-height: 40px;
  word-wrap: break-word;
}

.SmsMessage-v-s-p {
  text-align: center;
  color: black;
  font-size: 16px;
  font-family: 'Rubik';
  font-weight: 400;
  word-wrap: break-word;
  line-height: 100%;
}


}
@media (max-width: 480px) {
  .Sms-v-s-p {
    width: 50vw;
    height: 40vh;
    padding: 24px;
    background: white;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
  .login-container-login {

  }
  .Sms-v-s-p {
    width: 70%;
    height: 40vh;

  }
  .frame21-login {
    width: 100%; 
    top: 5%;
    justify-content: center;
  }

  .easylaw-logo-login {
    width: 100px;
    height: 100px;
  }

  .frame1707478209-login {
    max-width: 60vw;
    
  }
    .frame19-login {
        align-self: stretch;
        height: 30vh;
        width: 60vw;
        background: white;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;
    }
  .title-login {
    width: 100%;
  }

  .title-text-login {
    font-size: 24px; 
    line-height: 32px;
  }

  .clone-login {
    height: 36px; 
  }

  .submit-button-login {
    height: 44px; 
    font-size: 18px; 
  }

  .VerificationCode-v-s-p {
    width: 90%;
    height: 15vh;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    flex-direction: row-reverse;
}

.VerificationInput-v-s-p {
  text-align: center;
  color: black;
  font-size: 14px;
  width: 3vw;
  height: 6vh;
  font-family: 'Rubik';
  font-weight: 400;
  line-height: 40px;
  word-wrap: break-word;
}
}
@media (max-width: 370px) {
  .Sms-v-s-p {
    width: 50vw;
    height: 40vh;
    padding: 24px;
    background: white;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
.VerificationCode-v-s-p {
  width: 90%;
  height: 15vh;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex-direction: row-reverse;
}
.VerificationInput-v-s-p {
  text-align: center;
  color: black;
  font-size: 14px;
  width: 20%;
  height: 6vh;
  font-family: 'Rubik';
  font-weight: 400;
  line-height: 40px;
  word-wrap: break-word;
}
}
@media (max-width: 840px) {
  .VerificationInput-v-s-p {
    text-align: center;
    color: black;
    font-size: 14px;
    width:25%;
    height: 10vh;
    font-family: 'Rubik';
    font-weight: 400;
    line-height: 40px;
}
  .Sms-v-s-p {
    width: 55vw;
    height: 50%;
    padding: 24px;
    background: white;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}
}