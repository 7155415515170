
body,Html{
  box-sizing: border-box;
}

.container-f-w {
  width: 100%;
  position: relative;
  background: white;
  overflow: hidden; 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.frame21-f-w {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
}

.easylawLogo-f-w {
  width: 80px;
  height: 80px;
}

.title-f-w {
  width: 80%;
  max-width: 651px;
  margin-top: 10%;
  text-align: center;
  font-size: 18px;
  direction: rtl;
}

.frame1707478413-f-w {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.objects-f-w {
  width: 50px;
  height: 50px;

}

.vector-f-w {
  width: 90px;
  height: 90px;
  margin-top:5vh;
  text-align: center;
  
}

.titleLarge-f-w {

  text-align: center;
}

.largeText-f-w {
  font-size: 24px;
  color: #202341;
  font-family: 'Rubik';
  font-weight: 600;
  word-wrap: break-word;
  direction: rtl;
  margin-top: 5vh;
}

.easylawLogo-f-w {
  width: 122px;
  height: auto;
}

.easylawSms--f-w{
  /* line-height: 120%; */
margin: 7%;
margin-top: 5%;
text-align: center;
font-size: 18px;
direction: rtl;
}
@media (max-width: 768px) {
  .container-f-w {
      height: auto;
  }

  .frame21-f-w {
      width: 90%;
      max-width: 720px;
  }
  .easylawSms--f-w{
    line-height: 120%;
  
  margin-top: 10%;
  text-align: center;
  font-size: 18px;
  direction: rtl;
  }

  .title-f-w {
      width: 90%;
      max-width: 500px;
      margin-top: 15%;
      direction: rtl;
  }



  .objects-f-w {
      width: 45px;
      height: 45px;
  }



  .largeText-f-w {
      font-size: 28px;
  }

}


@media (max-width: 480px) {


  .frame21-f-w {
      width: 100%;
 
  }

  .easylawSms--f-w {
    line-height: 120%;
    font-size: 16px;
    text-align: center;
 
}
  .title-f-w {
      width: 95%;
      max-width: 80%;
      direction: rtl;

  }



  .objects-f-w {
      width: 40px;
      height: 40px;
  }

  .titleLarge-f-w {
      padding: 0;
  }

  .largeText-f-w {
      font-size: 1.7rem;
  }
}
