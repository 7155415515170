.container{
  overflow: hidden;
  overflow-y: auto;
  overflow-x: none;
  scrollbar-width: none; /* הסתרת פס גלילה עבור Firefox */
  -ms-overflow-style: none;  /* הסתרת פס גלילה עבור IE ו-Edge */
  min-height: 100vh; 
  min-width: 100vw;
  display: flex;
  flex-direction: column;
}


.container {

  width: 100vw;
  height: 100vh;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
}


.frame13{
  width: 100vw;
  height: auto;
  position: absolute;
  scrollbar-width: none; 
  -ms-overflow-style: none; 
  background: #f1fbfe;
  top: 25%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.frame16 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 6%;
  padding-bottom: 3%;
  gap: 16px;
}



.frame14{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  margin-top: 5%;
}
.title-begin {
  align-self: flex-end;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 1.125rem;
  font-family: 'Rubik';
  font-weight: 600;
  line-height: 40px;
  word-wrap: break-word;
  text-align: center;
  color: black;
  direction: rtl;
}
.title{
  align-self: stretch;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 32px;
  font-family: 'Rubik';
  font-weight: 600;
  line-height: 40px;
  word-wrap: break-word;
  text-align: center;
  color: #202341;
  direction: rtl;
}

.subtitle {
  align-self: stretch;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
}

.subtitle div {
  padding-left:3vw;
  padding-right:3vw;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  font-size: 18px;
  font-family: 'Rubik';
  font-weight: 600;
  line-height: 24px;
  word-wrap: break-word;
  text-align: center;
  color: #202341;
  direction: rtl;
}

.important-notice {
  margin-left: 10%;
  margin-right: 10%;
  background-color: #202341;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  font-size: 18px;
  font-family: 'Rubik';
  font-weight: 600;
  line-height: 24px;
  word-wrap: break-word;
  text-align: center;
  color: white;
  direction: rtl;
}

.icon-help {
  width: 10px;
  height: 0.1%;
  position: relative;
  display: inline-block;
}

.question-icon {
  color: grey;
  background-color: white;
  text-align: left;
  border-radius: 50%;
  font-size: 20px;
}


.tooltip {
  background-color: #333;
  color: #fff;
  border-radius: 4px;
  /* padding: 5vw; */
  font-size: 12px;
  max-width: 12%;
  height: 2%;
  
}
.tooltip-text {
  font-family: 'Rubik';
  font-size: 14px;
  font-weight: 400;
 
  color: #000000;
}

#questionIconTooltip{
  background-color: #202341;
  font-size: 12px;
  height: auto;
  padding-top: 0;
  padding-bottom: 0;
}
.frame1707478352 {

  padding-bottom: 5%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

}
.frame14-title{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

}

.frame15 {
  width: 50vw;
  padding-top: 10%;
  margin: auto; 
  background: white;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  
}

.solution-list {
  display: flex;
  flex-direction: column;
  
  }


  .solution-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    position: relative;
    padding-bottom: 20px; 
    border-bottom: 1px solid black; 
    
    

  }

  .answers {
  display: flex;
  gap: 3vw;
  }


.answer-option {
    padding: 12px 20px;
    border-radius: 8px;
    border: 1px solid #aba3a6;
    cursor: pointer;
    font-family: 'Rubik';
    word-wrap: 'break-word';
  }
  .answer-option.red {
    background-color: #ffcccc;
    border: 1px solid red;
}

  .answer-option.selected {
    background-color: #f7f03bf6;
  }
  .item-number {
    text-align: right;
    color: #013B82;
    font-size: 16px;
    font-family: 'Rubik';
    font-weight: 400;
    word-wrap: break-word;
    padding-left: 48px; 
  }

  .underline {
    border-bottom: 1px solid black;
    margin-top: 10px; 
}
  .frame20 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 92px;
    
  }
  
  .btn {
    width: 302px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-family: 'Rubik';
    font-weight: 600;
    line-height: 32px;
    word-wrap: break-word;
    text-align: center;
    cursor: pointer;
  }
  .cancel {
    border-radius: 8px;
    color: #202341;
  }
  
  .frame21-s-t {
    width: 100vw;
    position: fixed;
    height: 182px;
    top: 0;
    left: 0;
    background: white;
    box-shadow: 0px 2px 20.3px -2px rgba(27.73, 44.57, 182.84, 0.08);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 420px;
    z-index: 1000; 
  }
  
  .easylaw-logo {
    width: 122px;
    height: 125px;
  }
  
  .input-field-base {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
  
  .input-with-label {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .input {
    display: flex;
    align-items: center;
  }
  
  .dropdown {
    margin-right: 8px;
  }
  
  .dropdown select {
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .content {
    display: flex;
    align-items: center;
  }
  
  .text {
    margin-right: 8px;
  }
  
  .Button {
    width: 202px;
    height: 60px;
    border-radius: 8px;
    justify-content: flex-start;
    align-items: flex-start;
    display: inline-flex;
}

.ButtonMaster {
    flex: 1 1 0;
    height: 60px;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
    display: flex;
}

.ButtonInner {
    flex: 1 1 0;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid #202341;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
}

.ButtonText {
    flex: 1 1 0;
    align-self: stretch;
    padding: 16px;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    display: flex;
    text-align: center;
    color: #202341;
    font-size: 24px;
    font-family: 'Rubik';
    font-weight: 600;
    line-height: 32px;
    word-wrap: break-word;
}
.TwoButtons {
  display: flex;
  justify-content: center; 
  gap: 20px; 
  margin-bottom: 10vh;
  align-items: center;
}



.buttonApprove{
  margin-left: 10%;
  width: 18vw;
  height: 9vh;
  padding: 16px;
  border-radius:8px;
  border: none;
  gap: 8px;
  color: '#202341';
  font-size: 24px;
  font-family: 'Rubik';
  font-weight: 600;
  line-height: 32px;

  background-color: #21FFF5;


}

.CancelButton {
  margin-right: 10%;
  width: 21vw;
  height: 9vh;
  border: none;
  flex: '1 1 0' 1;
  padding: 16px;
  border-radius: 8px;
  gap: 8px;
  color: #202341;
  font-size: 24px;
  font-family: 'Rubik';
  font-weight: '600';
  line-height: 32px;
  background-color: #FFFFFF;
}

.buttonApprove, .CancelButton {
  padding: 10px 20px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap; 
}
.popup-overlay-1 {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}


.popup-content {
  margin: 0 auto;
  text-align: center;
  height: auto;
  background: white;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.HeaderContainer {
  align-self: stretch;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

}

.HeaderTextContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 3%;
  gap: 8px;
}

.Title {
  text-align: right;
  color: #202341;
  font-size: 32px;
  font-family: 'Rubik';
  font-weight: 600;
  line-height: 40px;
  direction: rtl;
}

.Subtitle {
 
  color: black;
  font-size: 1rem;
  font-family: 'Rubik';
  font-weight: 600;
  line-height: 24px;
  white-space: nowrap;
}

.OptionsContainer {
  align-self: stretch;
  height: auto;
  margin: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2vh;
}

.Options {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 2%;
  gap: 16px;
}

.ButtonColorPicker {
  padding: 14px 24px;
  background: white;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.10);
  border-radius: 8px;
  border: 1px solid #D0D5DD;
  justify-content: center;
  gap: 18px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  color: black;
  font-size: 16px;
  font-family: 'Rubik';
  font-weight: 400;
  line-height: 24px;
  word-wrap: 'break-word';
}

.ButtonColorPicker .Selected {
  background: #D0D5DD;
}
.book-icon{

  width: 24px;
  height: 24px;
  padding-left: 2px;
  position: relative;
}




.SubmitButton{
  display: flex;
  align-self: stretch;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-align: center;
  color: black;
  font-size: 24px;
  font-family: 'Rubik';
  font-weight: 500;
  padding: 3%;
  word-wrap: break-word;
  border: none;
}

.border-topopup{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;

}

.displayPopup-popup3{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.custom-select {
  width: 130px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: white;
  border: 1px solid #ccc; 
  padding: 10px;
  padding-right: 30px; 
  font-size: 16px;
  color: black;

  background-repeat: no-repeat;
  background-position: left 10px center; 
  background-size: 12px 12px;
  direction: rtl; 
}

.custom-select:focus {
  border-color: #666;
  outline: none;
}

select.custom-select {
  width: 130px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: white;
  border: 1px solid #ccc; 
  padding: 10px;
  padding-right: 30px;
  font-size: 16px;
  color: black;
  background-repeat: no-repeat;
  background-position: left 10px center; 
  background-size: 12px 12px;
}

select.custom-select option {
  background-color: white;
  color: black;
  font-size: 16px;
  padding: 10px;
}

select.custom-select option:hover {
  background-color: #f0f0f0;
}

select.custom-select option:checked {
  background-color: #e0e0e0;
}


.showPopupToGetPartTwo{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

@media only screen and (max-width: 768px) {
  .container {
    width: 100vw;
  }


  .tooltip {
    background-color: #333;
    color: #fff;
    border-radius: 4px;

    padding: 0;
    font-size: 10px;
    max-width: 12%;
 
    
  }
  .tooltip-text {
    font-family: 'Rubik';
    font-size: 14px;
    font-weight: 400;
   
    color: #000000;
  }
  
  #questionIconTooltip{
    background-color: #202341;
    font-size: 8px;
    height: auto;
    padding-top: 0;
    padding-bottom: 0;
  }
  .frame13 {
    /* padding-top: 3vh; */
    display: flex!important;
    justify-content: center!important;
    align-items: center!important;
    width: 100%;
    top: 14%;
    margin-bottom: 10%;
    width: 100%;
    top: 8%;


  }

  .frame16 {
    align-self: stretch;
    height: auto; 
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    margin-top: 6%;
    margin-bottom: 2vh;
  }

  
  .frame14, .frame1707478352 {
    width: 100%;
   
  }
  .frame14-title{
    width: 50%;
  }

  .frame15{
 
    margin: auto
  }

   .title{
    font-size: 24px;
   }
  .subtitle, .important-notice {
    font-size: 18px;
    text-align: center;
  }

  .answers {
    justify-content: center;
  }

  .answer-option {
    padding: 8px 12px;
    font-size: 14px;
  }

  .item-number {
    font-size: 14px;
    text-align: right; 
    padding-left: 2.5vh;
    
  }

  .frame21-s-t {
    height: auto;
    padding: 10px;

  }

  .easylaw-logo {
    width: 60px;
    height: 60px;
}

  .input-field-base {
    width: 100%;
  }

  .dropdown select {
    width: 100%;
    padding: 8px;
  }

  .TwoButtons {
    width: 70%;
    display: flex;
    flex-direction: row;
    justify-content: space-between; 
    gap: 10px;
  }

  .buttonApprove, .CancelButton {
    width: 70vw;
    height: auto;
    padding: 10px;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .popup-content {
    width: 90%;
    padding: 1%;
    height: auto;
  }


  .Title {
    font-size: 24px;
    font-weight: 600;
}
  .Subtitle{

  }

  .ButtonColorPicker {
    padding: 10px;
    font-size: 14px;
  }

  .SubmitButton {
    font-size: 18px;
    font-weight: 600;
        padding: 5%;
  
  }
}

@media only screen and (max-width: 768px) {

  .solution-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 10%;
    margin-right: 10%;
}
}
@media (max-width: 375px) {
  .solution-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 20%;
    margin-right: 20%;
}

  .TwoButtons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
}
.buttonApprove, .CancelButton {
  width: 70vw;
  height: auto;
  padding: 10px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.frame14-title{
  width: 50%;
}
.frame21-s-t {
  height: auto;
  padding: 10px;

}
.title{
  font-size: 24px;
 }
 .subtitle, .important-notice {
  font-size: 18px;
  text-align: center;
}
.dropdown select {
  width: 100%;
  padding: 8px;
}
.frame15 {
  width: 80vw;
  align-self: stretch;
  height: auto;

  margin-bottom: 10%;
  background: white;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}



}

@media (max-width: 480px) {

  .solution-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 15vw;
    margin-right: 15vw;
}
.frame15 {
  width: 80vw;
  align-self: stretch;
  height: auto;
  margin-bottom: 10%;
  background: white;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

  
  .solution-list {
    display: flex;
    flex-direction: column;
    justify-content: center;

}


  .TwoButtons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
}
.buttonApprove, .CancelButton {
  width: 70vw;
  height: auto;
  padding: 10px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.frame14, .frame1707478352{
  width: 100%;
}
.frame14-title{
  width: 50%;
}

.frame21-s-t {
  height:auto;

  gap: 20px;
}
.title{
  font-size: 1.7rem;
  
 }
 .subtitle, .important-notice {
  font-size: 14px;
  text-align: center;
}
.dropdown select {
  width: 100%;
  padding: 8px;
}
}

@media (max-width: 1240px) {
  .popup-container-p-m-a-c {
    width: 70vw;
    align-items: center;
    display: flex;
    height: 35%;
    height: auto;
}

  .TwoButtons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
}

.buttonApprove, .CancelButton {
  width: 70vw;
  height: auto;
  font-size: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}
.frame15 {
  width: 80vw;
  background: white;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  
}

.frame14, .frame1707478352{
  width: 100%;

}
.frame14-title{
  width: 50%;
}
}