

.admin-cards-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); 
    column-gap: 1cm; 
    row-gap: 0.5cm; 
    justify-items: center;
    margin-top: 12vh;
    margin-left: 2%;
    margin-right: 2%;
}

.admin-card {
    width: 100%;
    min-height: 20%;
    padding: 3.5%;
    background: white;
    border-radius: 12px;
    border: 1px #D0D5DD solid;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
    transition: box-shadow 0.3s ease;
    position: relative; 
}



.admin-card .three-dots-btn {
    display: none; 
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    cursor: pointer;
}

.admin-card:hover .three-dots-btn {
    display: inline-block; 
}
.content {
    align-self: stretch;
    flex: 1 1;
    padding-top: 2%;
    padding-bottom: 2%;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.avatar-section {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.avatar {
      width: 55px;
    height: 55px;
    margin-bottom: 2%;
    background-color: #EFFBFF;
    position: relative;
    border-radius: 50%;
}

.data-section {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 4px;
}
.admin-name {
    margin: 0;
    font-family: 'Rubik';
    font-weight: 500;
    font-size: 1.1rem;
    letter-spacing: 0em;
}

.admin-email {
    margin: 0;
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 1rem;
    letter-spacing: 0em;
}

.admin-phone {
    margin: 0;
    font-family: 'Rubik';
    font-weight: 400;
    font-size: 1rem;
    letter-spacing: 0em;
}

.phone-section,
.email-section {
    display: inline-flex;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 8px;
}

.role-badge {
    padding: 1% 4%;
    border-radius: 16px;
    justify-content: center;
    align-items: center;
    display: inline-flex;
}

.super-admin {
    background: rgba(142, 48, 255, 0.12);
    color: #202341;
}

.admin {
    background: #FFDEA9;
    color: #202341;
}

.btn-add-admin {
    margin-top: 2vh;
    position: fixed;
    margin-right: 2vw;
    right: 0;
    color: #FEFEFE;
    background-color: #202341;
    direction: rtl;
    padding: 0.8%;
    border-radius: 10px;
    width: auto;
    line-height: 24px;
    font-weight: 600;
    font-size: 18px;
    font-family: 'Rubik';
    display: flex;
    justify-content: center;
    gap: 4px;
}

.btn-return-admin {
    margin-bottom: 8vh;
    margin-top: 2vh;
    position: fixed;
    margin-right: 2vw;
    right: 0;
    bottom: 0;
    color: #FEFEFE;
    background-color: #17ced4;
    direction: rtl;
    padding: 0.8%;
    border-radius: 10px;
    width: auto;
    line-height: 24px;
    font-weight: 600;
    font-size: 18px;
    font-family: 'Rubik';
    display: flex;
    justify-content: center;
    gap: 4px;
    border: none;
}
.btn-approve-add-admin{
    margin-bottom: 4vh;
    margin-top: 2vh;
    margin-right: 2vw;
    color: #FEFEFE;
    background-color: #202341;
    direction: rtl;
    border-radius: 10px;
    width: 6vw;
    height: auto;
    line-height: 24px;
    font-weight: 500;
    font-size: 14px;
    font-family: 'Rubik';
    display: flex;
    justify-content: center;
}

.btn-cancel-add-admin{
    margin-bottom: 4vh;
    margin-top: 2vh;
    background-color: #FEFEFE;
    border: none;
    color: #202341;
    border-radius: 10px;
    width: 6vw;
    height: auto;
    line-height: 24px;
    font-weight: 500;
    font-size: 14px;
    font-family: 'Rubik';
    display: flex;

}
.btn-approve-add-admin, .btn-cancel-add-admin {
    display: inline-block; 
    padding: 1% 2%; 
    font-size: 16px; 
    cursor: pointer;
}

.avatar2 {
    width: 3%;
    height: 3%;
    position: relative;
    background: #F9F5FF;
    border-radius: 200px;
}

.user-icon {
    border: #202341
}

.admin-card.hovered {
    border: 2px solid #0C82AB;
}

.admin-card .content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.admin-card .avatar-section {
    display: flex;
    align-items: center;
    margin-bottom: 5%;
}

.admin-card .admin-name {
    font-weight: 500;
}

.admin-card .data-section {
    display: flex;
    flex-direction: column;
}

.admin-card .email-section,
.admin-card .phone-section {
    display: flex;
    align-items: center;
}

.role-badge {
    margin-top: 3%;
}



.custom-radio {
    color: #4a4a4a;
}

.custom-radio.Mui-checked {
    color: #17C2D0 !important;
}

.radioGroup-s-m {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.radioLabel-s-m {
    font-size: 16px;
    color: #000;
}

.PopupDeleteAdmin-p-d-t {
    width: 30vw;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 4%;
    padding-bottom: 4%;
    background: white;
    border-radius: 16px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
    display: inline-flex;
    position: relative;
}


.custom-select-menu {
    position: absolute;
    background-color: white;
    border: 1px solid #D0D5DD;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    padding: 8px 0;
    width: 120px;
}

.custom-select-item {
    padding: 8px 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.custom-select-item:hover {
    background-color: #f0f0f0;
}

.custom-select-item span {
    margin-left: 8px;
}
