
body,Html{
  box-sizing: border-box;
  font-family: 'Rubik' !important;
}
.window-popup2 {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 16px;
  padding: 20px;
  padding-bottom: 5px;
  width: 409px;
  height: 416px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;

}

.Title-popup2 {
  padding-left: 10px;
  padding-right: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: flex;
  text-align: center;


  text-align: center;
  color: #0C82AB;
  font-size: 24px;
  font-family: "Rubik";
  font-weight: 600;
  line-height: 32px;
  word-wrap: break-word;

  align-self: stretch;
  align-items: flex-start;
  display: inline-flex;
  text-align: center;
}

.Title2-popup2 {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
  background: #E7F8FD;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  display: inline-flex;
}

.con-popup2 {
  text-align: center;
  color: black;
  font-size: 18px;
  font-family: "Rubik";
  font-weight: 600;
  line-height: 24px;
  word-wrap: break-word;
  direction: rtl;
}

.versionsDiv-popup2 {
  align-self: stretch;
  height: 102px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: flex;
}

.diss-popup2 {
  width: 165.5px;
  height: 48px;
  text-align: center;
  color: black;
  font-size: 16px;
  font-family: "Rubik";
  font-weight: 600;
  line-height: 24px;
  word-wrap: break-word;

}

.sure-popup2 {
  align-self: stretch;
  text-align: center;
  color: black;
  font-size: 16px;
  font-family: "Rubik";
  font-weight: 400;
  line-height: 24px;
  word-wrap: break-word;
}



.Frame22-popup2 {
  height: 20%;
}

.btn1-popup2 {
  display: flex;
  justify-content: center;
  height: 70%;
  background: #21FFF5;
  border-radius: 10px;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  display: flex;
  color: black;
  font-size: 18px;
  font-family: "Rubik";
  font-weight: 600;
  line-height: 24px;
  word-wrap: break-word;
  width: 70%;
  text-align: center;
  margin: 0 auto; 
}

.btn5-popup2 {
  width: 302px;
  height: 20px;
  display: inline-flex;
  gap: 24px;
  display: flex;
  align-self: stretch;
  justify-content: flex-start;
  cursor: pointer;
  align-items: flex-start;
  flex: '1 1 0';
  padding: 16px;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  text-align: center;
  color: #202341;
  font-size: 18px;
  font-family: "Rubik";
  font-weight: 600;
  line-height: 24px;
  word-wrap: break-word;
}

.PopupCorrectedVersion-popup3 {
  width: 300px;
  height: 400px;
  padding: 24px;
  background: white;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 32px;

}

.Subtitle-popup3 {
  align-self: stretch;
  text-align: center;
  color: black;
  font-size: 24px;
  font-family: "Rubik";
  font-weight: 600;
  line-height: 20px;
  word-wrap: break-word;

}

.Frame1707478403-popup3 {
  align-self: stretch;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  display: flex;
}

.Frame1707478402-popup3,
.Frame1707478404-popup3 {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: flex;
}

.Frame1707478404-popup3 {
  padding-left: 24px;
  border-left: 1px solid #D0D5DD;
}

.Frame1707478406-popup3 {
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  display: flex;
}



.Vector-popup3 {
  width: 20px;
  height: 17.46px;
  left: 2px;
  top: 3.02px;
  position: absolute;
  border: 1.50px solid #17C2D0;
}

.Options-popup3 {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  display: flex;
}

.ButtonColorPicker3-popup3 {
  padding: 14px 24px;
  background: white;
  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.10);
  border-radius: 8px;
  border: 1px solid #D0D5DD;
  justify-content: center;
  align-items: center;
  gap: 18px;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.ButtonColorPicker3-popup3.selected {
  background-color: #E7F8FD;
}

.ButtonColorPicker3-popup3 div {
  text-align: center;
  color: black;
  font-size: 16px;
  font-family: "Rubik";
  font-weight: 400;
  line-height: 24px;
  word-wrap: break-word;
}



.ButtonSub-popup3 {
  align-self: stretch;
  height: 1px;
  flex: 1 1 0;
  background: #21FFF5;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  display: flex;
  font-size: 18px;
  font-family: "Rubik";
  font-weight: 600;
  border: none;
}

.VerticalDivider-popup3 {
  width: 2px;
  background-color: #be3939;
  margin: 0 2px;
}

/* popupSendMessage.css */

.overlay-s-m {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-s-m {
  width: 32%;

  background: white;
  border: 1px solid #BBBBCB;
  border-radius: 8px;
  padding: 40px;
  box-sizing: border-box;
  text-align: right;
  direction: rtl;
}

.header-s-m {
  padding-bottom: 1%;
  border-bottom: 1px #BBBBCB solid;

}

.title-s-m {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 1%;
}


.subtitle-s-m {
  font-size: 16px;
  font-weight: 400;
  padding-top: 1px;

}

.section-s-m {
  margin-bottom: 2%;

}

.input-title-s-m {
  font-size: 16px;
  padding: 8px;
  color: #000000;
  border: none;
  border-radius: 5px;
  width: 100%;
  outline: none;
  line-height: 24px;
  font-weight: 600;
}

.radioGroup-s-m {
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding-bottom: 5%;
  border-bottom: 1px #BBBBCB solid;
}

.radioLabel-s-m {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.radioLabel-s-m input[type="radio"] {
  display: none;
}

.radioIcon-s-m {
  width: 20px;
  height: 20px;
  fill: white;
  stroke: #0C82AB;
  stroke-width: 1;
}

.radioLabel-s-m.selected .radioIcon-s-m {
  fill: #22A5B2;
}

.score-range-s-m {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.scoreInput-s-m {
  padding: 8px;
  background: #F0F6FF;
  border-radius: 4px;
  width: 80px;
  text-align: center;
  border: none;
  font-family: 'Rubik';
}

.custom-radio {
  color: #4a4a4a;
}

.custom-radio.Mui-checked {
  color: #0C82AB !important; 
}

.radioGroup-s-m {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.radioLabel-s-m {
  font-size: 16px;
  color: #000;
}

.radioLabel-s-m.selected {
  font-weight: bold;
}

.textField-s-m {
  width: 100%;
  height: 64px;
  border: 1px solid #BBBBCB;
  border-radius: 8px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
  box-sizing: border-box;
  font-family: 'Rubik';
}

.copyIcon-s-m {
  display: none;
  position: absolute;
  left: 10px;
}

.savedMessageItem-s-m.selected .copyIcon-s-m {
  display: block;
  text-align: left;
}

.savedMessageItem-s-m {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  position: relative;
}

.savedMessageItem-s-m.selected {
  background: #E6F1FF;
}

.buttonGroup-s-m {
  display: flex;
  justify-content: space-between;
  padding-top: 0;
}

.button-s-m {
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
}

.sendButton-s-m.enabled {
  background-color: rgb(25, 154, 190); 
  color: white;
}

.sendButton-s-m.disabled {
  background-color: #E9E9E9; 
  color: rgba(0, 0, 0, 0.50);
  font-weight: 600;
  line-height: 24px;
  cursor: not-allowed; 
}
.saveButton-s-m {
  background-color: transparent;
  color: #316EE5;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  word-wrap: break-word;
  font-family: "Rubik";
}

button.button-s-m.saveButton-s-m {
  color: #000;
  padding-right: 0;
}

button.button-s-m.sendButton-s-m {
  border: 4px;
}

.primaryButton-s-m {
  padding: 8px 24px;
  background-color: #3f51b5;
  color: white;
  border: none;
  border-radius: 0.1px;
  cursor: pointer;
}

.icon-s-m {
  margin-left: 8px;

}

.showSavedMessagesButton-s-m {
  cursor: pointer;
  color: #007bff;
  margin-bottom: 10px;
  margin-top: 1%;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.savedMessages-s-m {
  width: 100%;

  border-radius: 4px;
  padding: 4px;
  max-height: 120px;
  overflow-y: auto;
}

.savedMessages-s-m::-webkit-scrollbar {
  width: 12px; 
}

.savedMessages-s-m::-webkit-scrollbar-track {
  background: #f1f1f1; 
  border-radius: 10px; 
}

.savedMessages-s-m::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 10px; 
}

.savedMessages-s-m::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.savedMessagesHeader-s-m {
  text-align: right;
  color: #878787;
  font-size: 14px;
  font-family: "Rubik";
  font-weight: 400;
  line-height: 20px;
  word-wrap: break-word;
  margin-bottom: 10px;
}

.savedMessageItem-s-m {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background: white;
  border-radius: 4px;
}

.savedMessageItem-s-m:hover {
  background: #f0f6ff;
}

.messageDate-s-m {
  color: #878787;
  font-size: 12px;
  font-family: "Assistant";
  font-weight: 400;
  line-height: 20px;
  word-wrap: break-word;
  margin-right: 0%;
  flex: 1;
}

.messageTitle-s-m {
  color: #101828;
  font-size: 14px;
  font-family: "Rubik";
  font-weight: 400;
  line-height: 20px;
  word-wrap: break-word;
  right: 0;
  margin-right: 15px;
  flex: 1;

}

.cancel-button-p-s-c,
.approval-button-p-s-c {
  padding: 10px 20px;
  margin-left: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
  line-height: 24px;
}

.cancel-button-p-s-c {
  background-color: white;
}

.approval-button-p-s-c {
  background: #0C82AB;
  color: #fff;
  width: 28%;



}

.textField-p-s-c {
  width: 100%;
  height: 100%;
  border: 1px solid #0C82AB;
  border-radius: 8px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
  box-sizing: border-box;

}

.categoryOptions-s-m {
  width: 60%;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.10);

}

.categoryOptionsContainer-s-m {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  margin-top: 1px;
  z-index: 1000;
  max-height: 6%;
  width: 60%;
  align-items: flex-start;
  margin-right: 6%;
}

.option-s-m {
  padding: 8px 12px;
  cursor: pointer;
  white-space: nowrap;
  text-align: right;

}

.option-s-m:hover {
  background-color: #f0f0f0;
}


.radioLabel-s-m {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.radioLabel-s-m input[type="radio"] {
  display: none;
}

.radioIcon-s-m {
  width: 20px;
  height: 20px;
  fill: white;
  stroke: #0C82AB;
  stroke-width: 1;
}

.radioLabel-s-m.selected .radioIcon-s-m {
  fill: #22A5B2;
}

.downArrow-s-m {
  width: 12px;
  height: 12px;
  margin-right: 5px;
  display: none;
}

.radioLabel-s-m.selected .downArrow-s-m {
  display: inline;
}

.categoryOptions-s-m {
  margin-top: 10px;
}

.option-s-m {
  padding: 5px;
  cursor: pointer;

}

.option-s-m:hover {
  background-color: #EAF2FC;
}

.popup-container-p-m-a-c {
  height: 40%;
  background: white;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 4%;
}

.frame-content-p-m-a-c {
  align-self: stretch;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.frame-title-p-m-a-c {
  align-self: stretch;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.title .message-box-p-m-a-c{
  direction: rtl;
 }
.title-p-m-a-c {
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-family: 'Rubik', sans-serif;
  font-weight: 600;
  word-wrap: break-word;
}

.title-p-m-a-c>div {
  text-align: center;
  color: #0C82AB;
  font-size: 24px;
  line-height: 32px;
}

.message-box-p-m-a-c {
  background: #E7F8FD;
  padding: 8px 10px;
  justify-content: center;
  align-items: center;
}

.message-box-p-m-a-c>div {
  text-align: center;
  color: black;
  font-size: 18px;
  line-height: 24px;
}

.version-check-p-m-a-c {
  align-self: stretch;
  height: 72px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.version-text-p-m-a-c {
  width: 165.5px;
  text-align: center;
  color: black;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

.version-p-m-a-c {

  color: black;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

.frame-actions-p-m-a-c {
  align-self: stretch;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  bottom: 5%;

}

.button-container-p-m-a-c {
  flex: 1 1 0;
  height: 45px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.button-confirm-p-m-a-c {
  flex: 1 1 0;
  padding: 16px;
  cursor: pointer;
  background: #21FFF5;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: black;
  font-size: 18px;
  font-family: 'Rubik', sans-serif;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
}

.button-back-p-m-a-c {
  flex: 1 1 0;
  padding: 16px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #202341;
  font-size: 18px;
  font-family: 'Rubik', sans-serif;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
}

.icon-alt-arrow-right-p-m-a-c {
  width: 24px;
  height: 24px;
  position: relative;
}

.vector-p-m-a-c {
  width: 8px;
  height: 16px;
  position: absolute;
  left: 8px;
  top: 4px;
  background: #202341;
}
.window-popup2 {
  position: fixed;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 480px) {
  .popup-container-p-m-a-c {
    width: 60vw;
    height: 45%;
    background: white;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 7%;
  }
  .window-popup2 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    border-radius: 16px;
    padding: 6%;
    padding-bottom: 0;
    width: 65vw;
    height: 416px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

}
@media (max-width: 376px) {
  .popup-container-p-m-a-c {
    width: 60vw;
    height: 45%;
    background: white;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 8%;
  }
 
  .window-popup2 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    border-radius: 16px;
    padding: 6%;
    padding-bottom: 0;
    width: 65vw;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.btn1-popup2 {
  display: inline-flex;
  flex: '1 1 0' 1;
  justify-content: flex-start;
  width: 50vw;
  display: flex;
  justify-content: center;
  gap: 24px;
  height: 25%;
  align-items: flex-start;
  align-self: stretch;
  padding: 4%;
  background: #21FFF5;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  text-align: 'center';
  color: black;
  font-size: 18px;
  font-family: "Rubik";
  font-weight: 600;
  line-height: 24px;
  word-wrap: break-word;
  align-content: space-between;
  flex-wrap: nowrap;
  flex-direction: row;
}

}