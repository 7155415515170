

.SideMenu-s-b {
  width: 20%;
  height: 90%;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: inline-flex;
  transition: height 0.3s ease;
  position: relative;
  padding-right: 15%;
}

.SideMenu-s-b::before {
  content: "";
  position: absolute;
  left: 0; 
  top: 10%;
  height: 80%; 
  width: 2px; 
  background-color: #E6F1FF;
}


.SideMenu-s-b.expanded-s-b {
  height: 100%;
}

.Frame1707478340-s-b {
  padding-left: 0; 
  padding-right: 0;
  border-left: 2px solid #E6F1FF;
  gap: 40px;

}
.selected-s-b {
  background-color: #e6f1ff; 
  color: #333; 
}


.Frame1707478457-s-b {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  gap: 8px;
  display: flex;
}

.Frame1707478340_inner-s-b {
  cursor: pointer;
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 3%;
}
.Text-s-b {
  background-color: white;
color:#013B82;
  font-size: 16px;
  font-family: 'Rubik';
  font-weight: 400;
  line-height: 24px;
  word-wrap: break-word;
}

.Text.large-s-b {
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  white-space: nowrap;
  margin-bottom: 5%;
}

.IconAltArrowRight-s-b {
  position: relative;
  background-color: white;
color:#013B82
}

.VectorStroke-s-b {
  width: 7.50px;
  height: 15.50px;
  left: 8.25px;
  top: 4.25px;
  position: absolute;
  background: #242625;
}

.Frame1000002375-s-b {
  align-self: stretch;
  padding-left: 24px;
  padding-right: 24px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 70px;
  display: inline-flex;
}

.Frame1707478339-s-b {
    margin: 4%;
    height: 100vh;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
}

.Frame1707478337-s-b {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  display: flex;
}

.SideMenuButton-s-b {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background:transparent ;
  border: none;
  cursor: pointer;
  gap: 8px;
  direction: rtl; 
}
.SideMenuButton-s-b.selected-s-b {
  background-color: #e6f1ff;
}

.Dropdown-s-b {
  height: 20px;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Frame1707478461-s-b {
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  display: flex;
  cursor: pointer;
}
.side-bar{
  margin-top: 19%;
}
.Label-s-b {
  font-size: 16px;
  font-family: 'Rubik';
  font-weight: 600;
  line-height: 24px;
  color: #013B82;
  flex-grow: 1; 
  text-align: right; 
}

.SideMenuDropdown-s-b {
  align-self: stretch;
  height: 40px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  display: flex;
}

.Vector-s-b {
  width: 12.88px;
  height: 14.67px;
  left: 0.67px;
  top: 14px;
  position: absolute;
  transform: rotate(-90deg);
  transform-origin: 0 0;
  background: black;
}

.option-s-b {
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
