
.frame-container {
  margin-top: 2vh;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  align-items: flex-start;
  display: inline-flex;
  background-color:#e6f1ff24;
  ;
}
  .sidebar-container {
  
   position: fixed;
    right: 0;
    top: 12%;
    height: 100%;
  }
  
  .button-container-s-v {
    position: absolute;
    width: 20%;
    margin-bottom: 14vh;
    left:10%  }
  
  .update-button-s-v {
 
    width: 8vw;
    height: 8vh;
    background: #0C82AB;
    border-radius: 8px;
    color: #FEFEFE;
    font-size: 20px;
    font-family: 'Rubik';
    font-weight: 500;
    line-height: 32px;
    text-align: center;
    cursor: pointer;
    border: none;
    outline: none;
  }
  
  .update-button-s-v:hover {
    background: #086B8B;
  }
  
  .inner-frame-content {
    align-self: stretch;
    width: 70vw;
    height: 100vh;
    padding-left: 4vw;
    padding-right: 4vw;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1%;
    display: flex;
  }
  .input-table.error {
    border-color: red;
    background-color: #f8d7da;
}
  .header-frame {
    top: 39%;
    align-self: stretch;
    height: auto;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 24px;
    display: flex;
  }
  
  .title-frame {
    align-self: stretch;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 8px;
    display: inline-flex;
  }
  
  .title-s {
    text-align: right;
    color: black;
    font-size: 24px;
    font-family: Rubik;
    font-weight: 600;
    line-height: 32px;
    word-wrap: break-word;
  }
  
  .subtitle-frame {
    align-self: stretch;
    height: 24px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    
    display: flex;
  }
  
  .subtitle-container {
    align-self: stretch;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 20px;
    display: inline-flex;
  }
  
  .subtitle-inactive {
    text-align: right;
    color: #878787;
    font-size: 18px;
    font-family: Rubik;
    font-weight: 500;
    line-height: 24px;
    word-wrap: break-word;
    cursor: pointer;
  }
  
  .subtitle-active {
    border-bottom: 0.5px solid #0C82AB;
    text-align: right;
    color: #013B82;
    font-size: 18px;
    font-family: Rubik;
    font-weight: 600;
    line-height: 24px;
    word-wrap: break-word;
    cursor: pointer;
  }

.scrollable-table {
  width: 65vw;
  top: 4%;
  left:0;
  height: 60vh; 
  overflow-y: auto; 
  scrollbar-width: none; 
  -ms-overflow-style: none; 
 
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-collapse: separate;
  border-spacing: 0;
  border: 1px solid #013B82;
  border-radius: 10px; 
}


.scrollable-table::-webkit-scrollbar {
  display: none; 
  
  
}


  .versions-frame-s-v {
    background: white;
    justify-content: flex-start;
    align-items: center;
    display: inline-flex;
    font-size: 18px;
    line-height: 32px;
    font-weight: 600;
    text-align: center;
    margin-left: 10%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    height: 70vh;
    bottom: 5%;
  }
  
  .versions-table-s-v {
    width: 80vw;
    height: 70vh;
    overflow: auto;
 
  }

  
  .input-table {
    padding: 8px;
    text-align: center;
  }
  
  .input-table.even {
    background: rgba(208, 213, 221, 0.30);
  }
  
  .input-table input {
    width: 100%;
    border: none;
    outline: none;
    text-align: center;
    font-size: 14px;
    font-family: "Rubik";
    background: none;
  }
  
  
  .version-header {
    background: rgba(208, 213, 221, 0.30);
    border-bottom: 3px solid #013B82;
    text-align: center;
 direction: rtl;
    font-size: 18px;
    font-weight: 600;
  }
  
  .table-row {
    cursor: pointer;
  }
  
  .input-table {
    text-align: center;
  }
  
  .input-table.even {
    background: rgba(208, 213, 221, 0.30);
  }
  
  .input-table input {
    width: 100%;
    border: none;
    outline: none;
    text-align: center;
    font-size: 14px;
    font-family: "Rubik";
    background: none;
  }


.input-table.version-3 {
  border-right: 3px solid #013B82;
}

.version-3-header {
  border-right: 3px solid #013B82;
}

  .version-header .version-header{
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;

  }
  
  .version-header.version-5{
    border-left: 1px #013B82 solid;
    border-bottom: 3px solid #013B82;

    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
  }
  .version-header.version-4{
    border-bottom: 3px solid #013B82;
    border-left: 1px #013B82 solid;
    width: 35%;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
  .version-header.version-2{
    border-left: 1px #013B82 solid;
    border-bottom: 3px solid #013B82;

    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
  }
  .version-header.version-3{
    border-bottom: 3px solid #013B82;

    border-left: 1px #013B82 solid;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
  }
  .version-header.version-1{
    border-bottom: 3px solid #013B82;

    border-left: 1px #013B82 solid;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
  }
  .frame-container-s-v {
    width: 100%;
    min-height: 74890px;
    padding-top: 24px;
    padding-bottom: 24px;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 40px;
    display: inline-flex;
    /* overflow: auto; */
    position: relative;
    
  }
  
  .frame-1707478337-s-v .container-s-v {
    align-self: stretch;
    height: 50px;
    border-radius: 4px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .frame-1707478337-s-v .container-s-v:last-child {
    background: none;
  }
  
  .dropdown-s-v {
    
    height: 20px;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    display: inline-flex;
    color: #013B82;
    font-size: 16px;
    font-family: 'Rubik';
    font-weight: 600;
    line-height: 24px;
    word-wrap: break-word;
  }
  
  
  .dropdown-s-v {
    height: 20px;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    display: inline-flex;
    color: #013B82;
    font-size: 16px;
    font-family: 'Rubik';
    font-weight: 600;
    line-height: 24px;
    word-wrap: break-word;
  }
  
  
  .dropdown-active {
    background: #E6F1FF;
  }
  
  
  
  .subtitle-button, .dropdown-s-v {
    background: none;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  
  .button-active {
    background: #E6F1FF;
    border-radius: 5px;
    color: #013B82;
    font-size: 16px;
    font-family: Rubik;
    font-weight: 600;
    line-height: 24px;
  }
  
  
  .subtitle-button {
    background: none;
    color: #878787;
    font-size: 16px;
    font-family: Rubik;
    font-weight: 500;
    line-height: 24px;
  }
  
  
  .dropdown-s-v {
    height: 40px;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    display: inline-flex;
    color: #013B82;
    font-size: 16px;
    font-family: 'Rubik';
    font-weight: 600;
    line-height: 24px;
  }
  
  
  .label-s-v {
    margin-right: 8px;
  }
  .input-table {
    border: 1px solid transparent; 
    padding: 8px;
    outline: none;
  }
  
  .version-header {
    background: rgba(208, 213, 221, 0.30);
    border-bottom: 3px solid #013B82;
    padding: 16px;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
  
  }
  
  .table-row {
    display: table-row;
    border-bottom: 1px solid #013B82; 
  }
  
  
  .input-table {
    padding: 8px;
    text-align: center;
    border-right: 1px solid #013B82;
  }
  
  
  
  .input-table.even {
    background: rgba(208, 213, 221, 0.30);
  }
  
  .input-table input {
    width: 100%;
    border: none;
    outline: none;
    text-align: center;
    font-size: 14px;
    font-family: "Rubik";
    background: none;
  }
  
  .version-header {
    border-bottom: 2px solid #2196F3;
}
  
  
  
  
  
  
  
  
  
  
  .selected-row {
    border: 2px solid #21FFF5; /* צבע תכלת זורח */
    z-index: 1; /* להבטיח שהמסגרת תהיה מעל לתוכן */

}

  .highlighted {
    border: 2px solid red;
}

  
  