
 /* TopBar.css */

 .DropdownMenu {
  position: absolute;
  top: 60px;
  right: 10px;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 1000;
}

.DropdownItem {
  display: flex;
  align-items: center; 
  padding: 10px 20px;
  cursor: pointer;
  font-size: 14px;
  direction: rtl;
}

.DropdownItem:hover {
  background-color: #e6f1ff;
  
}

.icon {
  margin-left: 8px; 
  font-size: 18px;
}




 .Frame1707478335-t-b {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0px -5px 40px #EAF2FC;
  border: 1px #F5F5F5 solid;
}
.Frame21-t-b {
  display: flex;
  align-items: center;
  gap: 40px; 
}
.Frame21-t-b img {
  height: 50px;
  width: auto;
}

.title_top {
  font-size: 18px;
  font-weight: 480;
  color: #013B82;
}

.Frame1707478442-t-b {
  display: flex;
  align-items: center;
}

.SolutionText-t-b {
  margin-right: 10px;
  font-size: 16px;
  font-weight: 500;
  color: #666;
}

.SwitchOn-t-b.active {
  background: #17C2D0;

}
.Frame1707478345-t-b {
  display: flex;
  align-items: center;
  border-radius: 8px;

}
.Rectangle700-t-b {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}

.Ellipse83-t-b {
  position: absolute;
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 50%;
  transition: left 0.3s ease;
}

.SwitchOn-t-b.active .Ellipse83-t-b {
  left: calc(100% - 18px);
}

.Frame1707478315-t-b {
  display: flex;
  gap: 15px;
}

.Frame1707478311-t-b {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 600;
  color: #013B82;
  background-color: #FFFFFF;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  display: flex;
  align-items: center;
  gap: 8px;
  word-wrap: break-word;
  font-family: 'Rubik';


}

.Frame1707478311-t-b:active,
.Frame1707478311-t-b.active {
  background: #E7F8FD;
  border: 0.50px #013B82 solid;
}

.Vector-t-b {
  width: 20px;
  height: 20px;
  color: #013B82;
}

.Frame1208-t-b {
  display: flex;
  align-items: center;
  gap: 10px;
}

.Frame603-t-b {
  display: flex;
  align-items: center;
  gap: 10px;
}

.Group602-t-b {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.Name-t-b {
  font-size: 16px;
  font-weight: 600;
  color: #333;
}

.SuperAdmin-t-b {
  font-size: 14px;
  color: #999;
}

.VuesaxOutlineArrowDown-t-b {
  display: flex;
  align-items: center;
}

.ArrowDown-t-b {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .Frame1707478335-t-b {
    flex-direction: column;
    align-items: flex-start;
  }

  .Frame21-t-b img {
    height: 40px;
  }

  .title_top {
    font-size: 20px;
  }

  .Frame1707478315-t-b {
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
  }

  .Frame1707478311-t-b {
    padding: 8px 16px;
    font-size: 14px;
  }
}
