
 table th {
  font-family: 'Rubik';
  /* תוכל להוסיף גם עיצובים נוספים אם תרצה, כמו גודל גופן */
}

/* סגנון לעמודת "שם" ליישור לימין */
table td.name-column {
  text-align: right;
  font-family: 'Rubik'; /* להבטחת עקביות */
}

  .table-container-s-t {
    width: 65vw;
    min-height: 45vh; /* ניתן לשנות את הגובה לפי הצורך */
    overflow-y: auto; /* גלילה אנכית */
    scrollbar-width: none; /* הסתרת פס גלילה עבור Firefox */
    -ms-overflow-style: none;  /* הסתרת פס גלילה עבור IE ו-Edge */
    /* bottom: 50%; */
    margin-bottom: 3%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-collapse: separate; /* שינוי ל-separate כדי לאפשר פינות מעוגלות */
    border-spacing: 0; /* הסרת מרווחים בין התאים */
    border-radius: 10px; /* הוספת פינות מעוגלות */
    min-width: 100%;
  }
  .delete-icon {
    color: rgba(5, 19, 92, 0.966);
    background: none;
}

  /* הסתרת פס גלילה עבור דפדפני Webkit */
  .table-container-s-t::-webkit-scrollbar {
    display: none; 
    
    
  }
  .ButtonFilterIsOurStudent-s-t {
    width: 200px;
    padding: 16px;
    border-radius: 8px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 8px;
height: 50px;
border:none;
    padding: 16px 24px;
    background: #9accdd;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 8px;

    text-align: center;
    color: #FFFFFF;
    font-size: 16px;
    font-family: 'Rubik';
    font-weight: 400;
    line-height: 24px;
}

.scores-s-t{
  height: 100vh;
  background-color: #e6f1ff24;
}
/* GradePointAverage.css */
.Frame1707478279-s-t {
    width: 80%;
    height: 90%;
    /* padding-top: 16px; */
    padding-left: 24px;
    padding-right: 24px;
    background: #FFFFFF;
    border-radius: 8px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 16px;
    display: inline-flex;
  }
  
  .Frame1707478253-s-t {
    align-self: stretch;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 8px;
    display: inline-flex;
  }
  
  .Frame1707478319-s-t {
    flex: 1 1 0;
    height: 28px;
    justify-content: space-between;
    align-items: flex-end;
    display: flex;
    
  }
  
  .Frame-s-t {
    width: 28px;
    height: 28px;
    position: relative;
  }
  
  .Vector-s-t {
    width: 28px;
    height: 28px;
    left: 0;
    top: 0;
    position: absolute;
  }
  
  .Vector-s-t .Small-s-t {
    width: 4.67px;
    height: 4.67px;
    position: absolute;
    border: 1.75px black solid;
  }
  
  .title-s-t {
    width: 809px;
    text-align: right;
    color: black;
    font-size: 18px;
    font-family: 'Rubik';
    font-weight: 600;
    line-height: 24px;
    word-wrap: break-word;
   
  }
  
  .Section-s-t {
    align-self: stretch;
    height: 104px;
    padding-bottom: 24px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
    display: flex;
  }
  
  .Container-s-t{
    align-self: stretch;
    height: 80px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
    
    display: flex;
  }
  
  .MetricGroup-s-t {
    width: 100%;
    height: 100vh;
    padding-top: 12px;
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 12px;
    background: rgba(237, 241, 248, 0.51);
    border-radius: 8px;
    align-self: stretch;
    justify-content: flex-start;
    align-items: center;
    gap: 48px;
    display: inline-flex;
  }
  
  .GradePointAverage-s-t {
    flex: 1 1 0;
    height: 50px;
    padding: 16px;
    background: white;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.06);
    
    border-radius: 8px;
    border: 1px #EAECF0 solid;
    justify-content: space-between;
    align-items: flex-end;
    display: flex;
  }
  
  .Number-s-t {
    /* width: 45px; */
    align-self: stretch;
    text-align: right;
    color: black;
    font-size: 36px;
    font-family: 'Inter';
    font-weight: 600;
    line-height: 44px;
    word-wrap: break-word;
  }
  
  .Frame1707478281-s-t {
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    display: inline-flex;
  }
  
  .LinearSchoolPassport-s-t, .LinearSchoolNotebook-s-t, .LinearSchoolNotebookBookmark-s-t {
    width: 24px;
    height: 24px;
    position: relative;
  }
  
  .LinearSchoolPassport-s-t .Vector-s-t, 
  .LinearSchoolNotebook-s-t .Vector-s-t, 
  .LinearSchoolNotebookBookmark-s-t .Vector-s-t {
    border: 1.50px solid;
  }
  
  .LinearSchoolPassport-s-t .Vector-s-t:first-child {
    width: 16px;
    height: 17px;
    left: 4px;
    top: 5px;
  }
  
  .LinearSchoolPassport-s-t .Vector-s-t:nth-child(2) {
    width: 6px;
    height: 6px;
    left: 9px;
    top: 10px;
    border-radius: 9999px;
  }
  
  .LinearSchoolPassport-st .Vector-s-t:nth-child(3) {
    width: 14px;
    height: 3.69px;
    left: 4px;
    top: 2.31px;
  }
  
  .LinearSchoolPassport-s-t .Vector-s-t:last-child {
    width: 4px;
    height: 0px;
    left: 10px;
    top: 19px;
  }
  
  .LinearSchoolNotebook-s-t .Vector-s-t:first-child,
  .LinearSchoolNotebookBookmark-s-t .Vector-s-t:first-child {
    width: 20px;
    height: 17.46px;
    left: 2px;
    top: 3.02px;
  }
  
  .LinearSchoolNotebook-s-t .Vector-s-t:nth-child(2),
  .LinearSchoolNotebookBookmark-s-t .Vector-s-t:nth-child(2) {
    width: 0px;
    height: 15.15px;
    left: 12px;
    top: 5.85px;
  }
  
  .LinearSchoolNotebook-s-t .Vector-s-t:nth-child(3),
  .LinearSchoolNotebookBookmark-s-t .Vector-s-t:nth-child(3),
  .LinearSchoolNotebook-s-t .Vector-s-t:nth-child(5),
  .LinearSchoolNotebookBookmark-s-t .Vector-s-t:nth-child(5) {
    width: 4px;
    height: 1px;
  }
  
  .LinearSchoolNotebook-s-t .Vector-s-t:nth-child(4),
  .LinearSchoolNotebookBookmark-s-t .Vector-s-t:nth-child(4),
  .LinearSchoolNotebook-s-t .Vector-s-t:nth-child(6),
  .LinearSchoolNotebookBookmark-s-t .Vector-s-t:nth-child(6) {
    transform: rotate(180deg);
    transform-origin: 0 0;
  }
  
  .LinearSchoolNotebookBookmark-s-t .Vector-s-t:nth-child(7) {
    width: 4px;
    height: 4.5px;
    left: 15px;
    top: 5.5px;
  }
  
  .Heading-s-t {
    text-align: right;
    color: black;
    font-size: 16px;
    font-family: 'Rubik';
    font-weight: 600;
    line-height: 24px;
    word-wrap: break-word;
  }
/* ScoresTable.css */
.Icon-s-t {
    width: 24px;
    height: 24px;
    margin-bottom: 8px;

  }
  



.Frame-s-t {
    border: none;
    background-color: #FFFFFF;
}




.IconMsg-s-t {
    background: #013B82;
    color: #FFFFFF;
}

.IconText-s-t {
    background: #0C82AB;
    color: #FFFFFF;
}

.border-blue-s-t {
    border-color: #013B82;
}

.border-orange-s-t {
    border-color: #DD9F6B;
}

.border-turquoise-s-t {
    border-color: #17C2D0;
}

.Frame1707478423-s-t {
    /* width: ; */
    height: 56px;
    padding-top: 5px;
    /* justify-content: space-between; */
    align-items: center;
    display: inline-flex;
}

.Frame1707478422-s-t {
    justify-content: center;
    align-items: center;
    gap: 24px;
    display: flex;

    
}


.ButtonSearch-s-t {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #013B82; /* Adjust according to design */
    font-family: 'Rubik';
    background-color: #FFFFFF;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin: 10px 0;
    width: 150px; /* Increase width */
    white-space: nowrap; /* Ensure text stays on one line */
    margin: 0; 
    
}

.Button-s-t {
    display: flex;
}

/* .ButtonMaster-s-t {
    width: 144px;
    display: flex;
} */

.Button-s-t {
    width: 100%;
    padding: 16px;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 8px;
}

.ButtonText-s-t {
    text-align: center;
    color: #202341;
    font-size: 16px;
    font-family: 'Rubik';
    font-weight: 400;
    line-height: 24px;
}

.IconSearch-s-t {
    width: 24px;
    height: 24px;
    position: relative;
    padding-left: 5%;
}

.VectorStroke-s-t {
    width: 22px;
    height: 22px;
    left: 1px;
    top: 1px;
    position: absolute;
    background: #013B82;
}

.Frame1707478421-s-t {
    justify-content: flex-end;
    align-items: center;
    gap: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.IconGroup-s-t {
    display: flex;
    cursor: pointer;
    gap: 16px;
    align-items: center;
    border: none;
    color: #000000;
    background-color: #FFFFFF;
    position: absolute;
    left: 2%;
}

.IconDownload-s-t
{
    width: 24px;
    height: 24px;
    position: relative;
}

.background-blue-s-t {
    background: #013B82;
}

.background-black-s-t {
    background: black;
}

.ButtonAdd-s-t {
    padding: 16px 24px;
    background: #0C82AB;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 8px;
}

.white-s-t {
    color: #FEFEFE;
}

.Vector-s-t {
    width: 24px;
    cursor: pointer;
    height: 24px;
    left: 0px;
    top: 0px;
    position: absolute;
}

.border-white-s-t {
    border: 1.75px solid white;
}

.top9-s-t {
    top: 9px;
}

.top13-s-t {
    top: 13px;
}
.ButtonAddFinish-s-t{
    width: 200px;
    padding: 16px;
    cursor: pointer;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 8px;
height: 50px;
border:none;
    padding: 16px 24px;
    background: #0C82AB;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 8px;

    text-align: center;
    color: #FFFFFF;
    font-size: 16px;
    font-family: 'Rubik';
    font-weight: 400;
    line-height: 24px;
}

.ButtonSendMessage-s-t{
    width: 200px;
    padding: 16px;
    cursor: pointer;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 8px;
height: 50px;
border:none;

    padding: 16px 24px;
    background: #013B82;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 8px;

    text-align: center;
    color: #FFFFFF;
    font-size: 16px;
    font-family: 'Rubik';
    font-weight: 400;
    line-height: 24px;
}


.search-input-s-t {
    margin: 10px 0;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 90%;
    box-sizing: border-box;
    font-size: 16px;
    border: 1px #0C82AB solid;
    transition: all 0.3s ease;
    padding-left: 2.5rem; /* Adjust this value to make room for the button */
    text-align: right;
}
.ButtonComplete-s-t {
  position: absolute;
  left: 0.5rem; /* Adjust this value to place the button correctly */
  top: 50%;
  transform: translateY(-50%);
  border: none;
  background: transparent;
  cursor: pointer;
  z-index: 1; /* Ensures the button is above the input */
}
.close-icon-s-t {
  width: 1rem; /* Adjust size of the icon if needed */
  height: 1rem;
}
.input-group-s-t {
    display: flex;
    width: auto;
    align-items: center;
     /* Add space between input and button */
     position: relative;
     display: flex;
     align-items: center;
}




.styled-table-s-t {
  min-width: calc(100% - 10px); 
  border-collapse: collapse;
  font-family: 'Rubik';
}

 .Subjects-s-t th {
  white-space: nowrap;
  font-family: 'Rubik';
 }
.styled-table-s-t thead tr {
  background-color: #F9FAFB;
  color: black;
  text-align: left;
}


.styled-table-s-t th,
.styled-table-s-t td {
  padding: 20px 15px;
  text-align: center;
}


.styled-table-s-t tbody tr {
  width: 13%;
  border-bottom: 1px solid #EAECF0; 
}

.styled-table-s-t tbody tr:nth-of-type(even) {
  background-color: white;
}

.edit-table-icon-s-t {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
  border:none;
}

.frame-s-t {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: none;
}

.icon-easylaw-s-t {
  display: flex;
  align-items: center;
  justify-content: center;
}

.easylaw-logo-s-t {
  width: 34px;
  height: 25px;
}

.radio-input-s-t {
  display: flex;
  align-items: center;
}

.radio-input-s-t input[type="radio"] {
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-right: 10px;
}

.radio-input-s-t input[type="radio"]:checked {
  background-color: #009879;
}

.radio-input-s-t input[type="radio"]:checked::after {
  content: '✓';
  color: #ffffff;
  font-size: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.pagination-container-s-t {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination-button-s-t {
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin: 0 10px;
}

.arrow-left-s-t,
.arrow-right-s-t {
  width: 24px;
  height: 24px;
}

.pagination-info-s-t {
  font-size: 1rem;
  font-weight: bold;
}

.condition{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.input-field {
  width: 100%;
  height: 40px;
  box-sizing: border-box; 
  border: 1px #0C82AB solid;
  font-size: inherit; 
  border-radius: 8px;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
  display: inline-flex;
}
.btn-update{
  width: 95px;
  height: 40px;
  cursor: pointer;
justify-content: center;
color: #0C82AB;
border-radius: 8px;
font-weight: 600;
line-height: 24px;
text-align: center;
background-color: white;
text-align: center;
border: none;
}
.title-marks{
  margin-top: 1%;
text-align: right;
color: black;
font-size: 24px;
font-family: 'Rubik';
font-weight: 600;
line-height: 32px;
word-wrap: break-word;
}
