body,Html{
    box-sizing: border-box;
}
.all-statistic{
    background-color: #e6f1ff24;
}
.answer-row {
    display: flex; 
    align-items: center; 
    gap: 10px; 
    margin-bottom: 10px; 
}
.countValue{
    white-space: nowrap;
    display: block;
}
.percentage-text {
    flex-shrink: 0; 
    width: 40px; 
    text-align: right; 
  
}

.percentage-bar {
    height: 20px;
    border-radius: 5px;
    color: white;
    text-align: center;
    line-height: 20px;
    transition: transform 1s ease-in-out;
    transform-origin: left; 
    flex-grow: 1; 
}

.green-bar {
    background-color: #ffeb3b; 
    color: black; 
    animation: expand-bar 1s forwards;
}

.yellow-bar {
    background-color: none;
    color: black; 
    animation: expand-bar 1s forwards;
}

@keyframes expand-bar {
    from {
        transform: scaleX(0);
    }
    to {
        transform: scaleX(1); 
    }
}

.frame-t-s{
    width: 1156px;
    height: 948px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    overflow: hidden; 
}
.header-container-t-s{
    display: flex;
    justify-content: flex-end;
    width: 20vw;
    right: 4%;
}
.header-title-t-s{
    margin-right: 0;
}

.header-title-t-s h2{
    font-family: 'Rubik';
    font-size: 24px;
    font-weight: 600;
    color: black;
    right: 22%;
    position: absolute;

}
.actions-container-t-s{
    display: flex;
    justify-content: space-between;
    width: 80%;
    position: fixed;
    right: 0;
}

.update-button-t-s{
    background-color: #0C82AB;
    color: #FEFEFE;
    font-family: 'Rubik';
    font-size: 24px;
    font-weight: 600;
    padding: 12px 24px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
   bottom: 3%;

}
.categories-t-s{
    display: flex;
 
}

.category-t-s{
    color: #878787;
  font-family: 'Rubik';
  font-size: 18px;
  font-weight: 500;
}

.category-t-s.active-t-s{
    border-bottom: 0.5px solid #0C82AB;
    color: #013B82;
    font-weight: 600;
}

.Frame17074782891-t-s{
    align-self: stretch;
    flex: 1 1 0;
    background-image: white;
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(79, 123, 242, 0.1);
    justify-content: flex-end;
    align-items: flex-start;
    display: inline-flex;
    margin-left: 0;
    margin-top: 0;
    top: 3%;
    width: 60vw;
    right: 30%;
}
.Frame17074782891-t-s::-webkit-scrollbar {
    display: none; 
  }

.Frame1707478297-t-s{
    flex: 1 1 0;
    height: 932px;
    justify-content: flex-end;
    align-items: flex-start;
    display: flex;
    overflow: hidden; /* הוספתי כדי להסתיר גלילה */
}

.Frame1707478424-t-s{
    flex: 1 1 0;
    align-self: stretch;
    justify-content: flex-end;
    align-items: center;
    display: flex;
}

.Frame1707478292-t-s{
    flex: 1 1 0;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: inline-flex;
    height: 100%;
}
.Container-t-s {
    display: flex;
    align-items: center; 
    gap: 8px; 
}

.Note-t-s {
    display: flex;
    align-items: center;
}

.AvatarAddButton-t-s {
    display: flex;
    align-items: center;
    cursor: pointer; 
}

.Icon-t-s {
    width: 16px;
    height: 16px;
}
.TableHeaderCell-t-s{
    align-self: stretch;
    height: 52px;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 4px;
    padding-bottom: 4px;
    background: #F9FAFB;
    border-radius: 8px;
    border-bottom: 1px #EAECF0 solid;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
    display: inline-flex;
}
.TableHeader-t-s{
    flex: 1 1 0;
    height: 24px;
    justify-content: flex-end;
    align-items: center;
    gap: 4px;
    display: flex;
}
.Text-t-s{
    flex: 1 1 0;
    text-align: center;
    color: #303236;
    font-size: 16px;
    font-family:'Rubik' ;
    font-weight: 600;
    line-height: 24px;
    word-wrap: break-word;
}
.subtitle-frame {
    align-self: stretch;
    height: 24px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    
    display: flex;
  }
.subtitle-container-t-s {
    align-self: stretch;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 20px;
    margin-top: 1%;
    display: inline-flex;
   right: 25vw;
   position: absolute;
  }
.Frame1707478263-t-s{
    align-self: stretch;
    height: 80px;
    padding-left: 16px;
    padding-right: 16px;
    border-bottom: 0.50px rgba(1, 59, 130, 0.50) solid;
    flex-direction: column;
    justify-content:center ;
    align-items: center;
    gap: 84px;
    display: flex;
}
.Frame1707478241-t-s{
    align-self: 'stretch';
    height: 80px;
    padding-left: 16px;
    padding-right: 16px;
    flex-direction: column;
    justify-content:center;
    align-items:center; 
    gap: 84px;
    display: flex
}
.Frame1707478232-t-s{
    align-self: stretch;
    height: 67px; 
    flex-direction:column;
    justify-content:center; 
    align-items:center;
    gap: 4px;
    display:flex
}
.Frame17074782271-t-s{
    width: 129px;
    height: 76px; 
    padding-left:16px;
    padding-right:16px; 
    justify-content:center; 
    align-items:center;
    gap: 8px;
    display:inline-flex
}
.AvatarAddButton-t-s{
    width: 32px;
    height: 32px;
    padding: 4px;
    background:white;
    border-radius: 50%;
    border:1px #878787 dotted;
    justify-content: 'center';
    align-items: 'center';
    display: 'flex'
}

.AvatarAddButton-t-s {
    width: 40px;
    height: 40px;
    border-radius: 50%;

    display: flex;
    justify-content: center;
    align-items: center;
}

.Icon-t-s {
    width: 20px; 
    height: 20px; 
}
.Note-t-s{
    width: 57px;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    background: #F0F6FF;
    border-radius: 4px;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
    display: flex;
    border: none;

}
.Icon2-t-s{
    width: 28px;
    height: 28px;
    position: relative;
    border-radius: 4px;
    overflow: hidden;

}
.VectorStroke-t-s{
    width: 19.87px;
    height: 19.87px;
    left: 4.07px;
    top: 4.07px;
    position: absolute;
    background:#0C82AB;
}
.Text2-t-s{
    text-align: center;
    color: #202341;
    font-size: 14px;
    font-family:'Rubik' ;
    font-weight: 400;
    line-height: 20px;
    word-wrap: break-word;
 
}
.Frame1707478289-t-s{
    flex: 1 1 0; 
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    display: inline-flex;
    height: 100%;
}
.Text-t-s{
    flex: 1 1 0;
    text-align:center;
    color: #303236;
    font-size:16px;
    font-family: 'Rubik';
    font-weight:600;
    line-height:24px;
    word-wrap:break-word;
}
.Frame1707478247-t-s{
    align-self:stretch;
    height:80px;
    padding-left:16px; 
    padding-right: 16px;
    border-bottom: 0.50px rgba(1, 59, 130, 0.50) solid;
    flex-direction:column; 
    justify-content:center;
    align-items:center;
    gap: 84px;
    display: flex;
}
.CorrectAnswer-t-s{
    padding-top: 8px;
    padding-bottom:8px;
    flex-direction:column;
    justify-content:flex-start;
    align-items: flex-end;
    gap: 8px;
    display: flex
}
.Frame1707478224-t-s{
    width: 40px;
    height: 40px;
    text-align: center;
    background: white;
    border-radius:4px;
    border: 0.50px #878787 solid;
    justify-content:flex-start;
    align-items:flex-end;
    display: inline-flex;
    color:black;
    font-size: 16px;
    font-family: 'Rubik';
    font-weight: 600;
    line-height: 24px;
    word-wrap: break-word; 
    flex-direction: column;
}

.Frame1707478287-t-s{
    flex: 1 1 0;
    flex-direction: column;
    justify-content: flex-start;
    align-items:center; 
    display: inline-flex;
    height: 100%;
}
.ResultsOfTheTest-t-s{
    align-self: stretch;
    height: 80px;
    padding-left: 24px;
    padding-right: 24px;
    border-bottom:0.50px rgba(1, 59, 130, 0.50) solid ;
    flex-direction:column ;
    justify-content: center;
    align-items: flex-end;
    gap: 4px;
    display: flex;
}
.Frame1707478272-t-s{
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    display: inline-flex;
}
.Frame1707478268-t-s{
    height: 14.50px;
    padding-left:4px;
    padding-right:4px;
    background:#C1DCFF;
    border-radius:4px;
    justify-content:flex-end;
    align-items:center;
    gap: 8px;
    display:flex;
}
.ResultPercentage-t-s{
    width: 23.50px; 
    height: 14.50px;
    text-align:right;
    color: rgba(1, 59, 130, 0.50); 
    font-size:12px;
    font-family:Rubik;
    font-weight:500;
    line-height:20px; 
    word-wrap: break-word
}
.ResultCount-t-s{
    width: 26px; 
    height: 11.50px;
    text-align: right;
    color: rgba(0, 0, 0, 0.50);
    font-size: 12px;
    font-family: Rubik;
    font-weight: 500;
    line-height: 20px;
    word-wrap: break-word;
}
.ResultAnswer-t-s{
    width: 9px; 
    height: 14px;
    color: black; 
    font-size: 16px;
    font-family: Rubik; 
    font-weight: 400;
    line-height: 24px;
    word-wrap:break-word;
}

/* Question Number Column 2 */
.Frame1707478291-t-s{
    flex: 1 1 0;
    align-self:stretch;
    flex-direction: column;
    justify-content: flex-start;
    align-items:flex-start;
    display: inline-flex;
}


.span-t-s{
    color: #303236;
    font-size: 16px;
    font-family: Rubik;
    font-weight: 600; 
    line-height: 24px;
    word-wrap: break-word
}
.span2-t-s{
    color:#303236 ;
    font-size: 12px;
    font-family: Rubik;
    font-weight: 400;
    line-height: 20px;
    word-wrap: break-word;
}

.Frame17074782272-t-s{
    height: 35px;
    justify-content:flex-end; 
    align-items:center;
    gap: 8px;
    display:inline-flex
}
.numOfQuestion-V2-t-s{
    text-align:right;
    color:black;
    font-size: 16px;
    font-family: Rubik;
    font-weight: 400;
    line-height: 24px;
    word-wrap:break-word;
}


.Frame1707478288-t-s{
    flex: 1 1 0;
    align-self: stretch;
    flex-direction:column; 
    justify-content: flex-start; 
    align-items:flex-start; 
    display: inline-flex
}

.NumberQuestion-t-s{
    align-self: stretch;
    height: 80px; 
    border-bottom: 0.50px rgba(1, 59, 130, 0.50) solid;
    flex-direction: column; 
    justify-content: flex-start; 
    align-items: center; 
    display: flex;
}
.Frame17074782273-t-s{
    height: 35px; 
    justify-content: flex-end; 
    align-items: center; 
    gap: 8px;
    display: inline-flex;
}
.numOfQuestion-V1-t-s{
    text-align: right;
    color: black;
    font-size: 16px;
    font-family: "Rubik";
    font-weight: 600;
    line-height: 24px;
    word-wrap: break-word;
}
.icon-arrow-open{
    left:0;
}

.Note-t-s {
    position: relative;
}

.Note-t-s:hover ~ .comments-container-t-s {
    display: block;
}
.table-container-t-s {
    right: 25%;
    width: 60vw;
    text-align: center;
    overflow: hidden;
    position: relative;
    overflow-y: auto;
    position: absolute;
    bottom: 5%;
    border-radius: 16px;
    height: 62vh;
    scrollbar-width: none;
    -ms-overflow-style: none;
    border: 2px solid #E6F1FF;
}
  .table {
    border-collapse: collapse; 
  }
  
  .table th, .table td {
    border: 0.5px solid rgba(1, 59, 130, 0.50); 
  }

  .TableHead .TableHead-t-s {
    position: fixed;
    top: 0vh; 
    background-color: #fff;
    z-index: 1; 
    box-shadow: 0 4px 8px rgba(79, 123, 242, 0.1);

  }
  

.TableCell-t-s, .TableHead-t-s, .Frame1707478263-t-s, .Frame1707478224-t-s {
    text-align: center;
    vertical-align: middle;
}


.TableCell-t-s {
    min-width: 120px;
}


.Container-t-s {
    display: flex;
    align-items: center;
    justify-content: center;
}

.Note-t-s {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.countValue {
    text-align: center;
}


.styledRow {
    background-color: #F0F6FF; 
    border-radius: 4px;
    margin-bottom: 8px;
    width: 80%; 
    text-align: center; 
    color: #b4b2b2; 
    font-weight: bold;
}

.optionLetter {
    background-color: #013B82;
    border-radius: 4px;
    margin-left: 8px;
    color: #ffffff;
    font-weight: bold;
    display: inline-block;
    text-align: center; 
}

.results-column {
    width: 30%; 
    text-align: center; 
}

.results-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.styledRow {
    display: flex;
    direction: rtl;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px; 
}

.optionLetter {
    width: 20px;
    text-align: center;
}

.countValueNumber {
    width: 40px;
    text-align: center;
}

.statisticBar {
    width: 100px;
}

.TableHead-t-s {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1; 
  }
  