@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');

body,html{
    font-family: 'Rubik';
}
.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* גובה מלא של המסך */
    /* background-color: #001f3f;  */
    background-color: white;
  }
  .logout-button {
    position: fixed; 
    z-index: 1; /* ערך גבוה כדי להבטיח שהוא מעל שאר האלמנטים */
    cursor: pointer;
    top: 30px; /* מרחק מהחלק העליון */
    right: 30px; /* מרחק מהצד הימני */
    background-color: #0C82AB; /* צבע תכלת */
    color: white; /* צבע טקסט לבן */
    border: none; /* ללא גבול */
    border-radius: 5px; /* עיגול פינות */
    padding: 8px 12px; /* ריווח פנימי */
    font-size: 14px; /* גודל טקסט */
    cursor: pointer; /* שינוי לסמן של לחצן */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* צל לכפתור */
    transition: background-color 0.3s ease; /* אפקט מעבר בצבע */
  }
  
  .logout-button:hover {
    background-color: #0c83abb7; /* צבע כהה יותר במעבר עכבר */
  }
  
  .logout-button:active {
    background-color: #0c83abbb; /* צבע כהה יותר בלחיצה */
  }
  