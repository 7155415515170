

@media (max-width: 768px) {
  .content-m {
    margin-left: auto;
    margin-right: auto;
    width: 80%; 
  }
}

@media (max-width: 480px) {
  .content-m {
    margin-left: auto;
    margin-right: auto;
    width: 90%; 
  }
}
.content-m {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  max-height: 60vh;
}

body,Html{
  box-sizing: border-box;
}
.container-m {
  background: white;
  position: relative;
  width: 60%;
  height: 60%;
  flex-direction: column;
  align-items: center;
  background-color: white;
  position: relative;
}

body, html {
  font-family: 'Rubik';
}

.header-m {
  margin-left: 9%;
  margin-top: 4%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.logo-m {
    width: 122.24px;
    height: 125.44px;
}
.txt-version{
  direction: rtl;
  font-size: 18px;
  white-space: nowrap;
}

.content-m {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.book-m {
  position: absolute;
  height: 80%;

  display: flex;
  justify-content: center;
}

.cloud-m {
  text-align: right;
}

.score-bar-m {
  width: 14.79px;
  height: 14.77px;
  margin: 8px 0;
  border: 2.31px solid #B4D7DC;
}

.main-score-m {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 0.2vw;
}

.greeting-m, .estimated-score-m, .score-m, .test-versions-m {
  color: #202341;
  font-family: 'Rubik';
 
}

.greeting-m {
  font-size: 20px;
  font-weight: 400;
  margin-top: 7%;
  direction: rtl;
}

.estimated-score-m {
  font-size: 24px;
  font-weight: 600;
  direction: rtl;
}

.title-score-m {
  margin-top: 0;
  direction: rtl;
  margin-bottom: 0;
}

.score-m {
  background: #F1FBFE;
  border-radius: 16px;
  font-size: 64px;
  font-weight: 600;
  padding: 2% 4%;
  width: 100%;
  height: 100%;
  background-color: #e7f8fd;
}

.test-versions-m {
  font-size: 14px;
  font-weight: 400;
}

.test-versions-m strong {
  font-weight: 500;
}
.downCsv{
  border: none;
  background: none;
  color: #f7f8f9;
  font-weight: 600;
  line-height: 24px;
  font-size: 14px;
  background-color: #0C82AB;
  border-radius: 10px;
  margin-top: 2vh;
  margin-bottom: 2vh;
  padding-right: 5%;
  padding-left: 5%;
  cursor: pointer;
}
.help-box-m {
  width: auto;
  padding: 1%;
  height: 10%;
  border-radius: 8px;
  border: 1px solid #0C82AB;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  direction: rtl;
  /* gap: 16px; */
  text-align: center;
  font-family: 'Rubik';
}

.details-link-m {
  font-size: 16px;
  font-weight: 400;
}

.details-link-m strong {
  font-weight: 600;
}

.click-here-m {
  color: #1063F3;
}
.home-link-m {
  display: block; 
  text-align: center; 
  margin: 20px auto; 
  font-size: 16px; 
  cursor: pointer; 
  text-decoration: none; 
  color: #0C82AB; 
}


@media (max-width:1300px){
  .content-m {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    max-height: 100vh;
}
}

@media (max-width: 1024px) {
  .container-m {
      width: 80%;
      height: 80%;
  }
  .header-m {
      margin-left: 5%;
      margin-top: 3%;
  }
  .logo-m {
      width: 100px;
      height: 100px;
  }


  .greeting-m, .estimated-score-m, .score-m, .test-versions-m {
      font-size: 18px;
  }
  .score-m {
      font-size: 48px;
  }
}


@media (max-width: 768px) {
  .container-m {
      height: 100%;
  }

  .header-m {
    margin-left: 5%;
    margin-top: 3%;
}
  .logo-m {
      width: 70px;
      height: 70px;
  }


  .greeting-m, .estimated-score-m, .score-m, .test-versions-m {
      font-size: 16px;
  }
  .score-m {
      font-size: 40px;
  }
  .help-box-m {
      padding: 1%
  }
  .details-link-m {
      font-size: 14px;
  }
}
@media (max-width: 480px) {

  .main-score-m {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 1vw;
  }
  .book-m {
   margin-left: 9vw; 
}

  .txt-version{
    font-size: 16px;
  }

}

@media (max-width: 375px) {
  .main-score-m {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 1vw;
  }
}