
body,Html{
  box-sizing: border-box;
}
  
  .note-content {
    align-self: stretch;
    height: 20%;
    padding: 4px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
  }
  
  .note-header {
    align-self: stretch;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 16px;
  }
  
  .author {
    text-align: right;
    color: #202341;
    font-size: 16px;
    font-family: 'Rubik', sans-serif;
    font-weight: 600;
    line-height: 24px;
    word-wrap: break-word;
    direction: rtl;
  }
  
  .avatar-question{
    width: 40px;
    height: 40px;
    position: relative;
    background: #F9F5FF;
    border-radius: 200px;
  }
  
  .user-icon {
    width: 60%;
    height: 60%;
    left:20%;
    top: 20%;
    position: absolute;
   
  }
  
  .avatar-online-indicator {
    width: 10px;
    height: 10px;
    left: 30px;
    top: 30px;
    position: absolute;
    background: #12B76A;
    border-radius: 5px;
    border: 1.5px white solid;
  }
  
  .note-body {
    align-self: stretch;
    padding: 8px;
    border-bottom: 1px solid #013B82;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    text-align: right;
    color: #878787;
    font-size: 14px;
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
    line-height: 20px;
    word-wrap: break-word;
  }

  .notes-container {
    position: absolute;
    left: 2%;
    top: 24%;
    width: 18%;
    max-height: calc(100vh - 10% - 24%);
    margin-bottom: 0;
    z-index: 700;
    padding: 8px 16px;
    background: white;
    box-shadow: 3px 3px 13.7px rgba(1, 59, 130, 0.25);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    overflow: hidden; 
}

.notes-content {
    flex: 1;
    overflow-y: auto;
    width: 100%;
    padding-right: 8px; 
    margin-bottom: 8px; 
    scrollbar-width: none; 
}

.notes-content::-webkit-scrollbar {
    display: none; 
}

.add-Comment {
    background: #013B82;
    border: none;
    border-radius: 8px;
    width: 96px; 
    height: 60px;
    margin: 2% 0 2% 2%;
    align-self: flex-end; 
    position: sticky; 
    bottom: 0; 
}

.close-button {
  position: absolute;
  left: 8px;
  background: transparent; 
  color: #0033cc; 
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1000;
  padding: 0;
}

   .frame1707478337-p-a-c {
    width: 444px;
    height: auto; 
    background: white;
    border-radius: 24px;
    border: 0.5px solid #CCCCCC;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .frame1707478212-p-a-c {
    align-self: stretch;
    padding: 24px;
    padding-bottom: 8px;
    border-bottom: 1px solid #CCCCCC;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
  }
  
  .frame1707478323-p-a-c {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }
  
  .frame1707478398-p-a-c {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 4px;
  }
  
  .header-text-p-a-c {
    text-align: right;
    color: black;
    font-size: 24px;
    font-family: 'Rubik', sans-serif;
    font-weight: 600;
    line-height: 32px;
    word-wrap: break-word;
  }
  
  .icon-container-p-a-c {
    padding-top: 6.12px;
    padding-left: 4.96px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  
  .vector-stroke-p-a-c {
    width: 24px;
    height: 24px;
    background: #0C82AB;
  }
  
  .subheader-text-p-a-c {
    text-align: right;
    color: #878787;
    font-size: 16px;
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
    line-height: 24px;
    word-wrap: break-word;
  }
  
  .frame1707478217-p-a-c {
    align-self: stretch;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
  }
  
  .frame1707478397-p-a-c {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 8px;
  }
  
  .text-label-p-a-c {
    color: #1C1C1C;
    font-size: 14px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    line-height: 20px;
    word-wrap: break-word;
  }
  
  .frame1707478396-p-a-c {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px;
  }
  
  .text-input-container-p-a-c {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 8px;
  }
  
  .textfield-base-p-a-c {
    align-self: stretch;
    padding: 12px 16px;
    background: white;
    border-radius: 8px;
    border: 1px solid #BBBBCB;
    display: flex;
    font-family: 'Rubik';
    justify-content: flex-end;
    align-items: flex-start;
    gap: 8px;
    resize: none;
    direction: rtl;
  }
  .textfield-base-p-a-c::placeholder {
    text-align: right;
    direction: rtl;
}

  .char-limit-p-a-c {
    align-self: stretch;
    text-align: right;
    color: rgba(0, 0, 0, 0.50);
    font-size: 14px;
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
    line-height: 20px;
    word-wrap: break-word;
  }
  
  .button-p-a-c{
    width: 142px;
    padding: 8px 24px;
    border-radius: 16px;
    border: none;
    cursor: pointer;
}

.cancel-button-p-a-c{
    background-color: transparent;
    color: #303236;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    word-wrap: break-word;
  }
  
  .confirm-button-p-a-c {

    background-color: #E9E9E9;
    color: rgba(0, 0, 0, 0.50);
    line-height: 24px;
    font-weight: 600;
    font-size: 18px;
    
  }

  button.button-p-a-c.cancel-button-p-a-c{
    border: 4px;
  }
  button.button-p-a-c.confirm-button-p-a-c {
  color: #000;
  text-align: center;
  }
  .button-p-a-c.confirm-button-p-a-c {
    background-color: #ccc; 
    color: #ffffff;
    cursor: not-allowed;
  }
  
  .button-p-a-c.confirm-button-p-a-c.active {
    background-color: rgb(25, 154, 190); 
    color: white;
    cursor: pointer;
  }
  
  
  .buttons-p-a-c{
    display: flex;
  justify-content: space-between;
  padding-top: 0;
  }
  .confirm-text-p-a-c {
    text-align: right;
    color: rgba(0, 0, 0, 0.50);
    font-size: 18px;
    font-family: 'Rubik', sans-serif;
    font-weight: 600;
    line-height: 24px;
    word-wrap: break-word;
  }
  
 
  .cancel-text-p-a-c {
    text-align: right;
    color: #303236;
    font-size: 18px;
    font-family: 'Rubik', sans-serif;
    font-weight: 600;
    line-height: 24px;
    word-wrap: break-word;
  }
  


.openPopupAddComm{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}











































