
.container-t-p {
    width: 100%;
    min-height: 100vh;
    overflow: auto;

    background: #e6f1ff24;
    justify-content: center; 
    align-items: center;

  }
  .custom-switch .Mui-checked {
    color: #22A5B2;
  }
  
  .custom-switch .Mui-checked + .MuiSwitch-track {
    background-color: #22A5B2;
  }
  .frame1707478338-t-p {
    width: 100%;
    max-width: 1432px; 
    padding: 24px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 24px;
  }
  
  .frame1366-t-p {
    flex: 1;
    width: 100%;
    margin-right: 5%;
    margin-left: 5%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  
  .frame1000002375-t-p {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .button-t-p {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: auto; 
    padding: 16px 24px;
    background: #22A5B2;
    border-radius: 8px;
    color: #FEFEFE;
    font-size: 18px;
    font-family: 'Rubik';
    font-weight: 600;
    line-height: 24px;
    text-align: right;
    gap: 8px;
    border: none;
    cursor: pointer;
  }
  
  .buttonIcon-t-p {
    width: 28px;
    height: 28px;
    position: relative;
  }
  
  .vector-t-p {
    width: 28px;
    height: 28px;
  }
  
  .titleText-t-p {
    color: black;
    font-size: 24px;
    font-family: 'Rubik';
    font-weight: 600;
    line-height: 32px;
    text-align: right;
  }
  
  .frame1151-t-p {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
    margin: 2%;
    direction: rtl;
    flex-wrap: wrap; 
  }
  
  .folderCandidates-t-p {
    width: 20%;
    min-height: 40vh;
    padding: 1.5%;
    background: white;
    box-shadow: 0px 2px 20.299999237060547px -2px rgba(27.73, 44.57, 182.84, 0.08);
    border-radius: 12px;
    border: 1px #D0D5DD solid;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    text-align: right;
    position: relative;
}
  
  .frame1327-t-p {
    width: 100%;
    height: 20%;
    border: none;
    padding-top: 16px;
    padding-bottom: 16px;

    background: #E6F1FF;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    gap: 8px;
    align-self: stretch;
    flex: 1 1 0;
    padding-top: 16px;
  }

  .frame1327-t-p {
    transition: box-shadow 0.3s ease; 
    cursor: pointer;
}

.frame1327-t-p:hover {
  background-color: #a8c5eaab;
}

  .line1-t-p {

    width: 100%;
    text-align: center;
    color: #090A09;
    font-size: 18px;
    font-family: 'Rubik';
    font-weight: 600;
    line-height: 24px;
    word-wrap: break-word;
    direction: rtl;
  }
  .line2-t-p {

    width: 100%;
    text-align: center;
    color: #090A09;
    font-size: 14px;
    font-family: 'Rubik';
    font-weight: 400;
    line-height: 24px;
    word-wrap: break-word;
    direction: rtl;
  }
  
  .frame1326-t-p {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 8px;
    padding-top: 6%;
    direction: ltr;
  }
  
  .frame1324-t-p {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 8px;
    
  }
  
  .iconsOutline-t-p {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .userPlus-t-p, .calendar-t-p {
    width: 24px;
    height: 24px;
    position: relative;
  }
  
  .vectorCircle-t-p {
    width: 8px;
    height: 8px;
    border-radius: 9999px;
    border: 1.5px #22A5B2 solid;
  }
  
  .vectorRectangle-t-p {
    width: 16px;
    height: 9px;
    border: 1.5px #22A5B2 solid;
  }
  
  .frame1325-t-p {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 8px;

  }
  
  .rectangle2-t-p, .lineHorizontal-t-p, .lineShort1-t-p, .lineShort2-t-p, .group220-t-p, .group218-t-p, .lineSmall1-t-p, .lineSmall2-t-p, .lineSmall3-t-p, .group219-t-p, .lineSmall4-t-p, .lineSmall5-t-p, .lineSmall6-t-p {
    position: absolute;
    border: 1.5px #22A5B2 solid;
  }
  







.dropdown-item-t-p {
  width: auto;
  height: 30%;
  padding: 0 16px;
  background: white;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  display: flex;
  align-items: center;
  color: #090A09;
  font-size: 16px;
  font-family: 'Rubik';
  text-align: right;
  cursor: pointer;
}

.dropdown-item-t-p:first-child {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.dropdown-item-t-p:last-child {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.three-dots-container-t-p {
  position: relative;
  width: 4px;
  height: 4px;
}

.three-dots-icon-t-p {
  position: absolute;
  top:7%;
  right: 7%;
  cursor: pointer;
} 

.dropdown-t-p {
  position: absolute;
  top: 30px;
  right: 12%;
  background: white;
  box-shadow: 0px 2px 20.3px -2px rgba(27.73, 44.57, 182.84, 0.08);
  border-radius: 16px;
  height: auto;
  padding: 0;
}

.dropdown-item-t-p {
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0; 
  border-bottom: 1px solid #f0f0f0; 
}

.dropdown-item-t-p:last-child {
  border-bottom: none; 
}

.dropdown-item-t-p:hover {
  background-color: #f0f0f0; 
}


.div-openDeletePopup{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.edit-title-input{
  border: 1px #013B82 solid;
  margin: 4% 4%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 90%;
  box-sizing: border-box;
  font-size: 16px;
  transition: all 0.3s ease;
 
  text-align: right;
}
.divOpenAddTestPopup{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.active-label {
  position: absolute;
  top: 10%;
  left: 10%;
  background-color: #22A5B2;
  padding: 5px 10px;
  border-radius: 10px;
  font-weight: 500;
  color: #f8f8f8;
}

.inactive-label{
  position: absolute; 
  top: 10%;
  left: 10%; 
  background-color: #d22222de;
  padding: 5px 10px; 
  border-radius: 10px; 
  font-weight: 500;
  color: #f8f8f8;
}