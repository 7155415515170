

.CommentWriting-p-a-n-t {
    width: 30%;
    height: 35%;
    padding-left: 3%;
    padding-right: 3%;
    padding-top: 3%;
    padding-bottom: 4%;
    background: white;
    border-radius: 16px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    display: inline-flex;
    position: relative;
}

.Frame1707478212-p-a-n-t {
    width: 100%;
    padding-bottom: 8px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
}

.Frame1707478323-p-a-n-t {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.Frame1707478398-p-a-n-t {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 4px;
}

.popup-title-p-a-n-t {
    text-align: right;
    color: black;
    font-size: 24px;
    font-family: 'Rubik';
    font-weight: 600;
    line-height: 32px;
}

.IconNewTest-p-a-n-t {
    width: 24px;
    height: 24px;
    position: relative;
}

.VectorStroke-p-a-n-t {
    width: 15.5px;
    height: 19.5px;
    left: 4.25px;
    top: 2.25px;
    position: absolute;
    background: black;
}

.Frame1707478217-p-a-n-t {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.TextFieldCaption-p-a-n-t {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.Frame21684-p-a-n-t {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
}

.label-p-a-n-t {
    text-align: right;
    color: #202341;
    font-size: 16px;
    font-family: 'Rubik';
    font-weight: 500;
    line-height: 24px;
    padding: 3%;
}

.TextField-p-a-n-t {
    width: 90%;
    height: 15%;
    padding: 4% 4%;
    background: white;
    border-radius: 8px;
    border: 1px #878787 solid;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
}

.input-placeholder-p-a-n-t {
    flex: 1;
    text-align: right;
    color: #202341;
    font-size: 16px;
    font-family: 'Rubik';
    font-weight: 300;
    line-height: 24px;
    border: none;
    outline: none;
    background: none;
}

.Frame22-p-a-n-t {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 24px;
}

.Button-p-a-n-t {
    flex: 1;
    justify-content: center;
    align-items: center;
    display: flex;
}

.ButtonMaster-p-a-n-t {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
}

.ButtonConfirm-p-a-n-t {
    width: 100%;
    padding: 16px;
    background: #E9E9E9;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.ButtonCancel-p-a-n-t {
    width: 100%;
    padding: 16px;
    background: #FFFFFF;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    display: flex;
    border: 1px solid #E9E9E9;
}

.ButtonText-p-a-n-t {
    text-align: center;
    font-size: 18px;
    font-family: 'Rubik';
    font-weight: 600;
    line-height: 24px;
}

.ButtonConfirm-p-a-n-t .ButtonText-p-a-n-t {
    color: rgba(0, 0, 0, 0.50);
}

.ButtonCancel-p-a-n-t .ButtonText-p-a-n-t {
    color: #202341;
}








.ButtonConfirm-p-a-n-t, .ButtonCancel-p-a-n-t {
    width: 100%;
    padding: 16px;
    border-radius: 8px;
    font-size: 18px;
    font-family: 'Rubik';
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    cursor: pointer;
  }
  
  .ButtonConfirm-p-a-n-t {
    background: #E9E9E9;
    color: rgba(0, 0, 0, 0.50);
    border: none;
  }
  
  .ButtonConfirm-p-a-n-t.ButtonConfirmActive {
    background-color: #22A5B2; 
    color: white;
  }
  
  .ButtonCancel-p-a-n-t {
    background: #FFFFFF;
    color: #202341;
    border: 1px solid #E9E9E9;
  }
  

  .ButtonConfirm-p-a-n-t:disabled {
    background-color: #E0E0E0;
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
  