

.PopupError-p-d-t {
    width: auto;
    padding-left: 7%;
    padding-right: 7%;
    padding-top: 5%;
    padding-bottom: 5%;
    background: white;
    border-radius: 16px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
    display: inline-flex;
    position: relative;
}

.Frame1707478455-p-d-t {
    align-self: stretch;
    height: 160px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    display: inline-flex;
}

.Frame14-p-d-t {
    width: 100%;
    height: 30%;
    align-self: stretch;
    padding-top: 8px;
    padding-bottom: 8px;
    background: #FDF3F3;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.Title2-p-d-t {
    padding-left: 10px;
    padding-right: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    display: flex;
    text-align: center;
    color: #D53435;
    font-size: 32px;
    font-family: 'Rubik';
    font-weight: 600;
    line-height: 32px;
    word-wrap: break-word;

}

.Title-p-d-t {
    padding-left: 10px;
    padding-right: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    display: flex;
    text-align: center;
    flex-direction: column; 
}

.Title-p-d-t .Message-p-d-t {
    align-self: stretch;
    text-align: center;
    color: black;
    font-size: 16px;
    font-family: 'Rubik';
    font-weight: 400;
    line-height: 24px;
    word-wrap: break-word;
}
.Message-p-d-t{
    padding-top: 16px;
    width: 341px;
    text-align: center;
}
.Title-p-d-t .ConfirmationQuestion-p-d-t {
    align-self: stretch;
    text-align: center;
    color: black;
    font-size: 18px;
    font-family: 'Rubik';
    font-weight: 600;
    line-height: 24px;
    word-wrap: break-word;
    direction: rtl;
}

.Frame22-p-d-t {
    align-self: stretch;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 24px;
    display: inline-flex;
    bottom: 10%; 
    justify-content: center;
    align-items: center;

}

.Button-p-d-t {
    flex: 1 1 0;
    height: 5%;
    width: 50%;
    justify-content: flex-start;
    align-items: flex-start;
    display: flex;
}

.ButtonMaster-p-d-t {
    flex: 1 1 0;
    justify-content: center;
    align-items: flex-start;
    gap: 24px;
    display: flex;
}

.ButtonConfirm-p-d-t {
    flex: 1 1 0;
    align-self: stretch;
    padding: 16px;
    background: #D53435;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    display: flex;
   cursor: pointer;
 
}

.ButtonCancel-p-d-t {
    flex: 1 1 0;
    align-self: stretch;
    padding: 16px;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    display: flex;
    cursor: pointer;

}

.ButtonText-p-d-t {
    text-align: center;
    font-size: 18px;
    font-family: 'Rubik';
    font-weight: 600;
    line-height: 24px;
    word-wrap: break-word;
}

.ButtonConfirm-p-d-t .ButtonText-p-d-t {
    color: white;
}

.ButtonCancel-p-d-t .ButtonText-p-d-t {
    color: #202341;
}