body {
  margin: 0;
  font-family: "Rubik"
   
}

code {
  font-family: "Rubik"
    
}
